<!--<button mat-icon-button routerLink="/menu">
  <mat-icon class="secondary-text-color">menu</mat-icon>
</button>-->
<div *ngIf="country | async as country">
  <div class="block-top-margin">
    <div fxLayoutAlign="center center" class="inner-block-bottom-margin-m">
      <mat-icon class="huge-flag flag" svgIcon="{{country.iso3}}"></mat-icon>
    </div>
    <span class="title" fxLayoutAlign="center center">{{country.name}}</span>
    <span *ngIf="country.visited" class="inner-block-top-margin-s green-color" fxLayoutAlign="center center">
      <app-action-item-with-icon [icon]="'check'" [innerText]="'Visited'" [swap]="true"></app-action-item-with-icon>
    </span>
  </div>

  <div class="block-top-margin">
    <div *ngIf="!country.edit then scratchBlock else editBlock" ></div>
  </div>

  <ng-template #scratchBlock>
    <textarea matInput
              cdkTextareaAutosize
              [cdkAutosizeMinRows]="3"
              placeholder="Add trip notes"
              class="width-100"
              [(ngModel)]="country.notes"></textarea>
    <div fxLayout="row" [fxLayoutGap]="layout.M" class="inner-block-top-margin-l">
      <button
              class="rounded-btn"
              mat-flat-button
              color="accent"
              (click)="onScratchClick(country)">
        <app-button-content innerText="Scratch it!" textClass="action-1" icon="done"></app-button-content>
      </button>
      <button
              mat-stroked-button
              class="rounded-btn transparent-btn"
              (click)="onCancelClick()">
        <span class="action-1">Cancel</span>
      </button>
    </div>
  </ng-template>
  <ng-template #editBlock>
    <textarea matInput
              cdkTextareaAutosize
              [cdkAutosizeMinRows]="3"
              placeholder="Add trip notes"
              class="width-100"
              (ngModelChange)="canBeSave = true"
              [(ngModel)]="country.notes"></textarea>
    <div fxLayout="row" fxLayoutAlign="end" [fxLayoutGap]="layout.M" class="inner-block-top-margin-l">
      <button *ngIf="canBeSave" class="rounded-btn"
              mat-flat-button
              color="accent"
              (click)="onSaveCountry(country)">
        <app-button-content icon="save" innerText="Save" textClass="action-1"></app-button-content>
      </button>
      <button class="transparent-btn"
              (click)="onDeleteCountry(country)"
              mat-icon-button>
        <mat-icon class="rnd-btn-icon">delete_outline</mat-icon>
      </button>
    </div>

  </ng-template>
</div>




