<div class="relative-position" ngClass.lt-lg="inner-block-top-margin-l">
  <span class="title" fxLayoutAlign="center center">All achievements</span>
</div>

<div *ngIf="auth.localUserData$ | async as user">
    <span class="secondary-text-color inner-block-top-margin-l inner-block-bottom-margin-xl h6" fxLayoutAlign="center center">
      {{user.achievements.length}} of {{allAchievsCount}} achievements unlocked</span>

    <div fxLayout="column" *ngFor="let achievements of groupedAchievements">
        <div fxLayout="row" class="inner-block-bottom-margin-m" [fxLayoutGap]="layout.M + ' grid'" [fxLayoutGap.lg]="layout.L + ' grid'">
            <div *ngFor="let item of achievements"
                (click)="viewDetails(user, item.achievement)" class="all-achievement-block clickable">
                <div *ngIf="isNotUserAchievement(user, item.achievement) then dummy else achievement"></div>
                <ng-template #dummy>
                    <dummy-achievement></dummy-achievement>
                </ng-template>
                <ng-template #achievement>
                    <achievement [achievement]="item.achievement"></achievement>
                </ng-template>
                <span class="square-text caption inner-block-top-margin-s inner-block-bottom-margin-m"
                    fxLayoutAlign="center"
                    [ngClass]="{'text-muted': isNotUserAchievement(user, item.achievement)}">{{item.title}}</span>
            </div>
        </div>
    </div>
</div>
