import {Component, Input} from '@angular/core';

@Component({
    selector: 'dummy-achievement',
    templateUrl: './dummy-achievement.component.html',
})

export class DummyAchievementComponent {
    @Input() tooltip: string;
}
