<span class="text-size">
        <app-action-item-with-icon [innerText]="data.name"
                                   textClass=""
                                   icon="done"
                                   iconClass="green-color icon-size"
                                   swap="true"
                                   [hideIcon]="data.actionType === types.SCRATCH"></app-action-item-with-icon>
</span>

<div *ngIf="data.actionType === types.SCRATCH; then scratchBlock else editBlock"></div>
<ng-template #scratchBlock>
  <div class="inner-block-top-margin-l">
    <button mat-button
            [fxHide]="readOnlyData"
            [disableRipple]="true"
            [autofocus]="false"
            (click)="editDetails(data, false)"
            color="$secondaryTextColor">
      <app-action-item-with-icon innerText="Add trip notes" textClass="action-1" icon="edit"></app-action-item-with-icon>
    </button>
    <div fxLayout="row" [fxLayoutGap]="layout.S" class="inner-block-top-margin-l">
      <button class="rounded-btn"
              [fxHide]="readOnlyData"
              mat-flat-button
              color="accent"
              [mat-dialog-close]="data">
        <app-button-content innerText="Scratch it!" textClass="action-1" icon="done"></app-button-content>
      </button>

      <button mat-stroked-button
              class="rounded-btn transparent-btn action-1"
              (click)="onCancelClick()">
        <span class="action-1">Cancel</span>
      </button>
    </div>
  </div>
</ng-template>
<ng-template #editBlock>
  <div class="inner-block-top-margin-l">
    <perfect-scrollbar class="notes-content" [usePSClass]=true>
      <span class="width-100" [innerHTML]="data.notes | textLineBreaker"></span>
    </perfect-scrollbar>

    <div fxLayout="row" [fxLayoutGap]="layout.S" class="inner-block-top-margin-l">
      <button mat-stroked-button
              [fxHide]="readOnlyData"
              (click)="editDetails(data, true)"
              class="rounded-btn transparent-btn">
        <app-button-content innerText="Edit details" textClass="action-1" icon="edit"></app-button-content>
      </button>
      <button mat-stroked-button
              class="rounded-btn transparent-btn"
              (click)="onCancelClick()">
        <span class="action-1">Cancel</span>
      </button>
      <button class="transparent-btn"
              [fxHide]="readOnlyData"
              [mat-dialog-close]="data"
              mat-icon-button>
        <mat-icon class="rnd-btn-icon">delete_outline</mat-icon>
      </button>
    </div>

  </div>
</ng-template>
