<div class="relative-position" ngClass.lt-lg="inner-block-top-margin-l">
    <!--  <div class="absolute-position">
        <button mat-icon-button routerLink="/menu">
            <mat-icon class="secondary-text-color">menu</mat-icon>
        </button>
    </div>-->
    <span class="title" fxLayoutAlign="center center">All flags</span>
</div>

<div *ngIf="auth.localUserData$ | async as user">
    <span class="secondary-text-color inner-block-top-margin-l inner-block-bottom-margin-xl h6"
          fxLayoutAlign="center center">
        {{user.visitedCountries.length}} of {{allFlagsCount}} flags collected</span>

    <div fxLayout="column" *ngFor="let countries of groupedCountries">
        <div fxLayout="row" class="inner-block-bottom-margin-m" [fxLayoutGap]="layout.M + ' grid'"
            [fxLayoutGap.lg]="layout.L + ' grid'">
            <div *ngFor="let country of countries"
                (click)="dataService.focusToCountry(country.iso3)" class="all-achievement-block clickable">
                <div class="square-container">
                    <mat-icon class="flag" [ngClass]="{'square-item': true, 'grayscale': isNotVisited(user, country.iso3)}"
                        svgIcon="{{country.iso3}}"></mat-icon>
                </div>

                <span class="square-text caption inner-block-top-margin-s inner-block-bottom-margin-m"
                      fxLayoutAlign="center"
                      [ngClass]="{'text-muted': isNotVisited(user, country.iso3)}">{{country.name}}</span>
            </div>
        </div>
    </div>
</div>

