import {AfterViewInit, Component, ElementRef, OnDestroy, ViewChild} from '@angular/core';
import {BreakpointObserver} from '@angular/cdk/layout';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {ActionHandlerService, AppAction} from './services/action-handler.service';
import {WindowService} from './services/window.service';
import {DataService} from './services/data.service';
import {BR_QUERY} from './custom-layout/custom-breakpoints';
import {interval, Subscription} from 'rxjs';
import {AuthService} from './services/auth.service';

const googleLogoURL = 'https://raw.githubusercontent.com/fireflysemantics/logo/master/Google.svg';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnDestroy {
  title = 'escratchmap';
  isXsScreen = false;

  @ViewChild('userContent')
  userContentRef: ElementRef;
  expanded = true;
  floatButtonAction: AppAction;
  private floatButtonActionSubscription: Subscription;

  constructor(breakpointObserver: BreakpointObserver,
              private matIconRegistry: MatIconRegistry,
              private route: Router,
              private domSanitizer: DomSanitizer,
              private dataService: DataService,
              public authService: AuthService,
              public windowService: WindowService,
              public actionService: ActionHandlerService, ) {

    breakpointObserver.observe([BR_QUERY.XS])
      .subscribe(result => {
        this.isXsScreen = result.matches;
      });

    this.floatButtonActionSubscription = actionService.floatButtonAction.subscribe(value => {
      this.floatButtonAction = value;
    });

    // TODO: fix this hook
    interval(100).subscribe(value => {
      if (this.userContentRef.nativeElement.clientWidth === 0) {
        this.windowService.notifyWindowResize();
      }
    });

    this.matIconRegistry.addSvgIconSet(this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/flags.svg'));
    this.matIconRegistry.addSvgIcon(
      'ellipse',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/ellipse.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'keyhole',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/keyhole.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'google',
      this.domSanitizer.bypassSecurityTrustResourceUrl(googleLogoURL)
    );

    this.matIconRegistry.addSvgIcon(
      'facebook',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/facebook.svg')
    );
  }

  onResized($event) {
    this.windowService.notifyWindowResize();
  }

  ngAfterViewInit(): void {
    this.dataService.userContentRef = this.userContentRef;
  }

  scrollToUserContent() {
    // TODO: add scrollTo functionality
    /*if (this.dataService.userContentRef) {
      this.dataService.userContentRef.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    }*/
  }

  ngOnDestroy(): void {
    this.floatButtonActionSubscription.unsubscribe();
  }

}
