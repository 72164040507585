import { Injectable } from '@angular/core';
import {AsyncSubject, BehaviorSubject, Subject} from 'rxjs';
import {Router} from '@angular/router';

export type CallbackFunction = (...args: any[]) => void;

export interface AppAction {
  type: string;
  callback: CallbackFunction;
}

export enum EventType {
  EMPTY,
  COUNTRY_WAS_REMOVED
}

@Injectable({
  providedIn: 'root'
})
export class ActionHandlerService {

  floatButtonActionStack: Array<AppAction> = new Array<AppAction>();

  public defaultFlatButtonAction: AppAction = {
    type: 'add',
    callback: () => this.router.navigate(['country-picker'], {queryParamsHandling: 'merge'})
  };

  public defaultCloseAction: AppAction = {
    type: 'close',
    callback: () => this.router.navigate(['/profile'], {queryParamsHandling: 'merge'})
  };

  floatButtonAction: BehaviorSubject<AppAction> = new BehaviorSubject({
    type: 'add',
    callback: () => this.router.navigate(['country-picker'], {queryParamsHandling: 'merge'})
  });

  eventDispatcher: Subject<EventType> = new Subject<EventType>();

  constructor(public router: Router) { }

  getNextAction() {
    return this.floatButtonActionStack.pop();
  }

  setNextAction(action: AppAction) {
    this.floatButtonActionStack.push(action);
  }
}
