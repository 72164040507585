import {AppErrorsType} from './app-errors-type';

export class AppError {

  errorType: AppErrorsType;
  errorMessage?: string;

  constructor(errorType: AppErrorsType, errorMessage?: string) {
    this.errorType = errorType;
    this.errorMessage = errorMessage;
  }
}
