<div class="achievement-content" fxLayout="column">
  <mat-icon (click)="dialogRef.close()" class="action-button secondary-text-color" [inline]="true">close</mat-icon>
  <div *ngIf="data.isUnlocked then achievement else dummy"></div>
  <ng-template #achievement>
      <div fxFlex class="achievement" fxFlexAlign="center">
        <img class="achievement-img" src="../../assets/achievents/{{data.achievement | lowercase}}.png">
      </div>
  </ng-template>
  <ng-template #dummy>
    <div fxFlex fxLayoutAlign="center center" class="dummy-achievement-container">
      <div class="achievement dummy-achievement" fxLayoutAlign="center center">
        <mat-icon svgIcon="keyhole" class="dummy-achievement-img"></mat-icon>
      </div>
    </div>
  </ng-template>

  <span fxFlex class="h3 inner-block-top-margin-m" fxFlexAlign="center">{{details.title}}</span>
  <span [fxHide]="!data.isUnlocked" fxFlex class="body-1 inner-block-top-margin-s" fxFlexAlign="center">{{details.description}}</span>
  <span [fxHide]="data.isUnlocked" class="caption inner-block-top-margin-m">This achievement was not unlocked yet</span>
</div>
