/* tslint:disable */
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-expandable-div',
  templateUrl: './expandable-div.component.html',
  styleUrls: ['./expandable-div.component.scss']
})
export class ExpandableDivComponent implements OnInit {
  @Input() showExpandableText = true;
  expanded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // @ts-ignore
  @ViewChild(PerfectScrollbarDirective) directiveRef?: PerfectScrollbarDirective;

  constructor() {
    this.expanded.subscribe(value => {
      if (!value) {
        this.scrollToTop();
      }
    });
  }

  public scrollToTop(): void {
    if (this.directiveRef) {
      this.directiveRef.scrollToTop(0, 500);
    }
  }

  ngOnInit() {
  }

  toggle(expanded: BehaviorSubject<boolean>) {
    expanded.next(!expanded.getValue());
  }
}
