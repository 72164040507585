<div class="common-bottom-margin-lg relative-position">
  <div class="absolute-position">
    <button mat-icon-button routerLink="/profile-setts">
      <mat-icon class="secondary-text-color">arrow_back</mat-icon>
    </button>
  </div>
  <div class="h4" fxLayoutAlign="center center">Change password</div>
</div>

<div *ngIf="auth.localUserData$ | async as user" fxLayout="column">
  <form fxLayout="column" (ngSubmit)="updatePassword()" [formGroup]="changePasswordForm" class="common-bottom-margin-lg" fxLayoutGap="24px">
    <mat-form-field>
      <input matInput
             placeholder="New password"
             [type]="hidePassword.password ? 'password' : 'text'"
             [formControl]="changePasswordForm.controls.password">
      <mat-hint>At least <strong>8</strong> characters long, must contain <strong>1</strong> number minimum</mat-hint>
      <button mat-icon-button matSuffix (click)="hidePassword.password = !hidePassword.password"
              [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hidePassword.password">
        <mat-icon>{{hidePassword.password ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
      <mat-error *ngIf="changePasswordForm.controls.password.hasError('required')">
        Password is <strong>required</strong>
      </mat-error>
      <mat-error *ngIf="changePasswordForm.controls.password.hasError('minlength')">
        Password must be at least <strong>8</strong> characters long
      </mat-error>
      <mat-error *ngIf="changePasswordForm.controls.password.hasError('pattern')">
        Password must contains at least 1 number
      </mat-error>
    </mat-form-field>
    <mat-form-field class="common-bottom-margin">
      <input matInput
             placeholder="Confirm password"
             [type]="hidePassword.repeatPassword ? 'password' : 'text'"
             [formControl]="changePasswordForm.controls.repeatPassword">
      <mat-hint>Repeat your new password</mat-hint>
      <button mat-icon-button matSuffix (click)="hidePassword.repeatPassword = !hidePassword.repeatPassword"
              [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hidePassword.repeatPassword">
        <mat-icon>{{hidePassword.repeatPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
      <mat-error *ngIf="changePasswordForm.controls.repeatPassword.hasError('required')">
        Password is <strong>required</strong>
      </mat-error>
      <mat-error *ngIf="changePasswordForm.controls.repeatPassword.hasError('passwordsNotEqual')">
        Passwords do not match
      </mat-error>
    </mat-form-field>
    <div fxLayout="row" fxFlexAlign="end" fxLayoutGap="16px">
      <button fxFlex="100px"
              [disabled]="!changePasswordForm.valid"
              [fxHide]="!changePasswordForm.dirty"
              type="submit"
              class="rounded-btn"
              mat-flat-button
              color="accent">
        <mat-icon [inline]="true" style="margin-right: 4px">done</mat-icon>
        <span>Save</span>
      </button>

      <button (click)="cancelUpdatePassword()" fxFlex="100px" [fxHide]="!changePasswordForm.dirty" mat-stroked-button class="rounded-btn transparent-btn">Cancel</button>
    </div>
  </form>

</div>
