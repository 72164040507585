import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Stat, User } from '../model/user.model';
import { Country } from '../model/country';
import { DecimalPipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

  constructor(private dataService: DataService, private decimalPipe: DecimalPipe) {
  }

  generate(user: User): Array<Stat> {
    const stats = new Array<Stat>();

    if (user.visitedCountries) {
      stats.push({title: 'countries visited', value: user.visitedCountries.length.toString()});

      this.calculateWorldExploredPercentage(stats, user, this.dataService.regions.getValue());
    }

    return stats;
  }

  calculateWorldExploredPercentage(stats: Array<Stat>, user: User, regions: Map<string, Array<Country>>) {
    if (regions) {
      let countries = 0;
      Object.keys(regions).forEach((key) => {
        countries = countries + regions[key].length;
      });

      stats.push({
        title: 'of the world explored',
        value: `${this.decimalPipe.transform((user.visitedCountries.length / countries) * 100, '1.0-2')}%`
      });
    }
  }
}
