import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {LayoutConstant} from '../model/layout-constant';
import {ActionHandlerService} from '../services/action-handler.service';

export enum CountryPickerActionType {
  SCRATCH,
  UNSCRATCH
}

export interface CountryMetadata {
  actionType: CountryPickerActionType;
  name: string;
  iso3: string;
  notes: string;
  polygonId: string;
}

@Component({
  selector: 'app-country-picker-modal',
  templateUrl: './country-picker-modal.component.html',
  styleUrls: ['./country-picker-modal.component.scss']
})
export class CountryPickerModalComponent implements OnInit {

  types = CountryPickerActionType;
  readOnlyData: boolean;
  layout = LayoutConstant;

  constructor(public dialogRef: MatDialogRef<CountryPickerModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: CountryMetadata,
              private router: Router,
              public dialog: MatDialog,
              private auth: AuthService,
              private actionHandler: ActionHandlerService) {

    this.auth.readOnlyData.subscribe(value => {
      this.readOnlyData = value;
    });
  }

  ngOnInit() {

  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  editDetails(data: CountryMetadata, edit: boolean) {
    this.dialogRef.close();
    this.actionHandler.setNextAction(this.actionHandler.defaultCloseAction);
    this.router.navigate(['/country-details-editor',
      {
        iso3: data.iso3,
        edit,
        visited: data.actionType !== this.types.SCRATCH
      }
        ],
      {queryParamsHandling: 'merge'});
  }
}
