import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {Router} from '@angular/router';
import {ActionHandlerService} from '../services/action-handler.service';
import {of, Subscription} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmationModalComponent} from '../confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-profile-settings',
  templateUrl: './profile-settings.component.html',
  styleUrls: ['./profile-settings.component.scss']
})
export class ProfileSettingsComponent implements OnInit, OnDestroy {

  userData = new FormGroup({
    name: new FormControl('')
  });

  private userSubscription: Subscription;
  private deleteAccount: boolean;

  constructor(public auth: AuthService,
              private router: Router,
              public dialog: MatDialog,
              private actionHandler: ActionHandlerService) {

    this.actionHandler.floatButtonAction.next({
      type: 'close', callback: () => {
        this.router.navigate(['/profile'], {queryParamsHandling: 'merge'});
      }
    });

    this.userSubscription = auth.localUserData$.subscribe(user => {
      if (user && user.displayName) {
        this.userData.controls.name.setValue(user.displayName);
      }
    });
  }

  onDeleteAccount() {
    this.dialog.open(ConfirmationModalComponent, {
      data:
        {
          title: `Delete account?`,
          description: 'All your data will be permanently deleted.',
          actionText: 'Delete',
          actionIcon: 'delete'
        }
    }).afterClosed().subscribe((confirmationResult) => {
      if (confirmationResult) {
        this.deleteAccount = true;
        this.auth.deleteAccount();
      }
    });
  }

  navigateTo(path: string) {
    this.router.navigate([path], {queryParamsHandling: 'merge'});
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (!this.deleteAccount) {
      const localUser = this.auth.localUserData$.getValue();

      if (localUser.displayName !== this.userData.value.name) {
        localUser.displayName = this.userData.value.name;
        this.auth.updateLocalUserData(localUser);
      }
    }

    this.userSubscription.unsubscribe();
  }

}
