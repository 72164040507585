import {ElementRef, Injectable} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
import {Country} from '../model/country';
import {BehaviorSubject, Subject} from 'rxjs';
import {MapPoint} from '../model/map-point.model';
import {AngularFirestore} from '@angular/fire/firestore';
import {User} from '../model/user.model';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  regions: BehaviorSubject<Map<string, Array<Country>>> = new BehaviorSubject<Map<string, Array<Country>>>(undefined);
  countryList: BehaviorSubject<Array<Country>> = new BehaviorSubject<Array<Country>>(undefined);

  countryPickerState: BehaviorSubject<any> = new BehaviorSubject<any>({searchField: '', expandedPanel: ''});
  userContentRef: ElementRef;

  mapData: Subject<Map<string, MapPoint>> = new Subject<Map<string, MapPoint>>();
  mapFocus: Subject<string> = new Subject<string>();

  mapResize: Subject<void> = new Subject<void>();

  constructor(private db: AngularFireDatabase,
              private afs: AngularFirestore) {
    this.db.object<Map<string, Array<Country>>>('/regions').valueChanges().subscribe(data => {
      this.regions.next(data);
      const regions = Object.keys(data);
      if (regions && regions.length > 0) {
        this.countryList.next(regions
          .map(value => data[value])
          .reduce((previousValue, currentValue) => previousValue.concat(currentValue)));
      }
    });
  }

  focusToCountry(iso3: string) {
    this.mapFocus.next(iso3);
  }

  getFriendData(id: string) {
    return this.afs.collection('users-readonly').doc<User>(id).valueChanges();
  }

  saveReadOnlyUser(user: User) {
    return this.afs.doc<User>(`users-readonly/${user.readOnlyId}`).valueChanges();
  }
}
