import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Achievement, AchievementInfo, achievementsInfoList} from '../model/user.model';

export interface AchievementModalData {
  achievement: Achievement;
  isUnlocked: boolean;
  achievementDate?: string;
}

@Component({
  selector: 'app-achievement-modal',
  templateUrl: './achievement-modal.component.html',
  styleUrls: ['./achievement-modal.component.scss']
})
export class AchievementModalComponent implements OnInit {
  details: AchievementInfo;

  constructor(public dialogRef: MatDialogRef<AchievementModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: AchievementModalData) {
    this.details = achievementsInfoList.find(value => value.achievement === data.achievement);
  }

  ngOnInit(): void {
  }

}
