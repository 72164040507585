import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {ActionHandlerService} from '../services/action-handler.service';
import {BreakpointObserver} from '@angular/cdk/layout';
import {Router} from '@angular/router';
import {DataService} from '../services/data.service';
import {Country} from '../model/country';
import {combineLatest, Subscription} from 'rxjs';
import {Achievement, achievementsInfoList, User} from '../model/user.model';
import {LayoutConstant} from '../model/layout-constant';
import {AchievementModalComponent} from '../achievement-modal/achievement-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {BR_QUERY} from '../custom-layout/custom-breakpoints';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit, OnDestroy {
  isSmallScreen: boolean;
  layout = LayoutConstant;

  homeCountryISO3: string;
  homeCountry: Country = null;
  countryList: Array<Country>;
  private subscription: Subscription;

  constructor(public auth: AuthService,
              public actionHandler: ActionHandlerService,
              public router: Router,
              public dataService: DataService,
              public dialog: MatDialog,
              breakpointObserver: BreakpointObserver) {
    this.actionHandler.floatButtonAction.next(this.actionHandler.defaultFlatButtonAction);

    this.auth.readOnlyData.next(false);

    const cachedUser = this.auth.cachedLocalUserData$.getValue();
    if (cachedUser) {
      this.auth.updateLocalUserData(cachedUser);
    }

    breakpointObserver
      .observe([BR_QUERY.LTLG])
      .subscribe(result => {
        this.isSmallScreen = result.matches;
      });

    this.subscription = combineLatest([this.dataService.countryList, this.auth.localUserData$]).subscribe(value => {
      const countryList = value[0];
      const user = value[1];

      if (countryList && user) {
        this.countryList = countryList;
        this.homeCountryISO3 = user.homeCountry;
        this.updateHomeCountry();
      }
    });
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private updateHomeCountry() {
    if (this.countryList) {
      this.homeCountry = this.countryList.find(country => country.iso3 === this.homeCountryISO3);
    }
  }

  navigateToCountryPicker() {
    const currentState = this.dataService.countryPickerState.getValue();
    currentState.searchField = this.homeCountry ? this.homeCountry.name : '';
    this.dataService.countryPickerState.next(currentState);

    this.router.navigate(['country-picker', {homeCountry: true}], {queryParamsHandling: 'merge'});
  }

  navigateTo(path: string): void {
    this.router.navigate([path], {queryParamsHandling: 'merge'});
  }

  viewAchievementDetails(achievement: Achievement) {
    this.dialog.open(AchievementModalComponent, {
      data: {
        achievement,
        isUnlocked: true
      }
    });
  }

  getAchievementDescription(achievement: Achievement) {
    return achievementsInfoList.find(value => value.achievement === achievement).description;
  }

  dummyAchievements(user: User) {
    return (this.isSmallScreen ? 4 : 5) - user.achievements.length > 0
      ? achievementsInfoList
        .filter(aInfo => !user.achievements.includes(aInfo.achievement))
        .slice(0, (this.isSmallScreen ? 4 : 5) - user.achievements.length)
      : [];
  }

  dummyCountries(user: User) {
    return this.countryList
    && (this.isSmallScreen ? 4 : 5) - user.visitedCountries.length > 0
      ? this.countryList
        .filter(country => !user.visitedCountries.map(cInfo => cInfo.iso3).includes(country.iso3))
        .slice(0, (this.isSmallScreen ? 4 : 5) - user.visitedCountries.length)
      : [];
  }
}
