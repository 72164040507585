<div (resized)="onResized($event)"
     class="match-parent relative-position"
     *ngIf="windowService.windowSize | async as windowSize">

  <div #userContent class="profile-gradient app-content"
       [ngStyle.xs]="{'top.px': windowSize.width}"
       [ngClass]="{'collapsed': !expanded}">
    <div class="app-content-child">
      <div class="small-padding" ngClass.lg="large-padding">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>

  <div class="app-map" [ngStyle.xs]="{'height.px': windowSize.width}">
    <app-map></app-map>
  </div>

  <div class="main-action-container"
       *ngIf="floatButtonAction"
       [fxHide]="!floatButtonAction"
       [ngClass]="{'collapsed': !expanded}"
       [ngStyle.xs]="{'top.px': windowSize.width - 20}">
    <button mat-mini-fab
            class="main-act-btn"
            aria-label="Add country"
            color="accent"
            (click)="scrollToUserContent();floatButtonAction.callback()">
      <mat-icon class="main-act-btn-icon" color="primary">{{floatButtonAction.type}}</mat-icon>
    </button>
  </div>
  <div [fxHide]=true
       [fxHide.sm]=false
       [ngClass]="{
       'collapsed': !expanded,
       'main-action-container': !floatButtonAction,
       'additional-action-container': floatButtonAction}">
    <button
      mat-mini-fab
      class="additional-act-btn"
      (click)="expanded = !expanded;">
      <mat-icon class="additional-act-btn-icon" color="primary">{{expanded ? 'chevron_left' : 'chevron_right'}}</mat-icon>
    </button>
  </div>
</div>



