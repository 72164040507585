import {Component, OnInit} from '@angular/core';
import {AuthProvider, AuthService} from '../services/auth.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ErrorHandlerService} from '../services/error-handler.service';
import {LayoutConstant} from '../model/layout-constant';

@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.scss']
})
export class UserLoginComponent implements OnInit {
  layout = LayoutConstant;
  hidePassword = true;

  loginForm = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.email,
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
      Validators.pattern('.*[0-9].*')
    ])
  });

  constructor(public auth: AuthService, private router: Router, private errorHandler: ErrorHandlerService) {
  }

  ngOnInit() {
  }

  async login(authProvider: AuthProvider) {
    const signingResult = await this.auth.providerSignin(authProvider);
    signingResult.mapErr(e => {
      this.errorHandler.showError(e.errorMessage.toString());
    });
    if (signingResult.isOk()) {
      await this.router.navigate(['profile'], {queryParamsHandling: 'merge'});
    }
  }

  async emailLogin() {
    const signinResult = await this.auth.emailSignin(this.loginForm.value);
    signinResult.mapErr(e => {
      this.errorHandler.showError(e.errorMessage.toString());
    });
    if (signinResult.isOk()) {
      await this.router.navigate(['profile'], {queryParamsHandling: 'merge'});
    }
  }
}
