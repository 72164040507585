<div class="new-achievement">
  <div class="achievement-content" fxLayout="row">
    <div fxFlex="64px" class="achievement">
      <img class="achievement-img" src="../../assets/achievents/{{data.achievement | lowercase}}.png">
    </div>
    <div fxFlex="208px" fxLayout="column" class="achievement-details">
      <span fxFlex class="achievement-title">Achievement unlocked!</span>
      <span fxFlex class="achievement-see-details" (click)="seeDetails()">See details</span>
    </div>
  </div>
  <div>
    <mat-icon (click)="snackBarRef.dismiss()" class="action-button secondary-text-color" [inline]="true">close</mat-icon>
  </div>
</div>
<div class="confetti">
  <div class="confetti-piece" repeat=""></div>
  <div class="confetti-piece"></div>
  <div class="confetti-piece"></div>
  <div class="confetti-piece"></div>
  <div class="confetti-piece"></div>
  <div class="confetti-piece"></div>
  <div class="confetti-piece"></div>
  <div class="confetti-piece"></div>
  <div class="confetti-piece"></div>
  <div class="confetti-piece"></div>
  <div class="confetti-piece"></div>
  <div class="confetti-piece"></div>
  <div class="confetti-piece"></div>
  <div class="confetti-piece"></div>
  <div class="confetti-piece"></div>
  <div class="confetti-piece"></div>
  <div class="confetti-piece"></div>
  <div class="confetti-piece"></div>
  <div class="confetti-piece"></div>
  <div class="confetti-piece"></div>
  <div class="confetti-piece"></div>
  <div class="confetti-piece"></div>
  <div class="confetti-piece"></div>
  <div class="confetti-piece"></div>
  <div class="confetti-piece"></div>
  <div class="confetti-piece"></div>
  <div class="confetti-piece"></div>
  <div class="confetti-piece"></div>
  <div class="confetti-piece"></div>
  <div class="confetti-piece"></div>
  <div class="confetti-piece"></div>
</div>
