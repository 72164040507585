import {Pipe, PipeTransform} from '@angular/core';
import {Country} from './model/country';

@Pipe({
  name: 'countryFilter'
})
export class CountryFilterPipe implements PipeTransform {

  transform(regions: Map<string, Array<Country>>, searchCriteria: string): any {
    if (regions && Object.keys(regions).length > 0 && searchCriteria) {
      const result = new Map<string, Array<Country>>();
      for (const [key, value] of Object.entries(regions)) {
        const filteredCountries = value.filter(c => c.name.toLowerCase().includes(searchCriteria.toLowerCase()));

        if (filteredCountries.length > 0) {
          result.set(key, filteredCountries);
        }
      }

      return result;
    } else {
      return regions;
    }
  }

}
