import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ErrorHandlerService} from '../services/error-handler.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {LayoutConstant} from '../model/layout-constant';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit, OnDestroy {

  ngUnsubscribe: Subject<any> = new Subject<any>();

  hidePassword = true;
  layout = LayoutConstant;

  private actionCodeChecked: boolean;
  private actionCode: string;

  savePasswordForm = new FormGroup({
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
      Validators.pattern('.*[0-9].*')
    ])
  });

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit() {
    this.activatedRoute.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
      if (Object.keys(params).length === 0) {
        this.router.navigate(['/profile'], {queryParamsHandling: 'merge'});
        return;
      }

      const {mode, oobCode} = params;

      if (mode === 'resetPassword') {
        this.authService.getAuth().verifyPasswordResetCode(oobCode).then(() => {
          this.actionCodeChecked = true;
          this.actionCode = oobCode;
        }).catch(() => {
          // Invalid or expired action code. Ask user to try to
          // reset the password again.
          this.actionCodeChecked = false;
          this.errorHandler.showError('Invalid or expired action code. Please reset the password again')
            .then(() => this.router.navigate(['/login']));
        });
      }
    });
  }

  async savePassword() {
    this.authService.getAuth().confirmPasswordReset(this.actionCode, this.savePasswordForm.value.password)
      .then(() => {
        this.errorHandler.showError('Password was saved successfully. Now you can login with new credentials')
          .then(() => this.router.navigate(['/login']));
      }).catch(reason => {
      this.errorHandler.showError('Invalid or expired action code. Please reset the password again')
        .then(() => this.router.navigate(['/login']));
    });
  }

  ngOnDestroy() {
    // End all subscriptions listening to ngUnsubscribe
    // to avoid memory leaks.
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
