import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {LayoutConstant} from '../model/layout-constant';

export interface ConfirmationModalData {
  title: string;
  description: string;
  closeText?: string;
  actionText: string;
  actionIcon?: string;
  action?: any;
}

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {

  layout = LayoutConstant;

  constructor(public dialogRef: MatDialogRef<ConfirmationModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ConfirmationModalData) {
  }

  ngOnInit() {
  }
}
