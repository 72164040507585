import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Country} from '../model/country';
import {DataService} from '../services/data.service';
import {AuthService} from '../services/auth.service';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {ActionHandlerService} from '../services/action-handler.service';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmationModalComponent} from '../confirmation-modal/confirmation-modal.component';
import {LayoutConstant} from '../model/layout-constant';

@Component({
  selector: 'app-country-picker',
  templateUrl: './country-picker.component.html',
  styleUrls: ['./country-picker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CountryPickerComponent implements OnInit {

  searchField = '';
  expandedPanel = '';
  handsetLandscape = false;
  regions: Map<string, Array<Country>>;
  filteredRegions: Map<string, Array<Country>>;
  showAction = '';
  visitedCountries: Array<string> = [];
  homeCountry: string;
  isHomeCountryPicker: boolean;
  layout = LayoutConstant;

  constructor(breakpointObserver: BreakpointObserver,
              private route: ActivatedRoute,
              public dataService: DataService,
              public authService: AuthService,
              private router: Router,
              public dialog: MatDialog,
              private actionHandler: ActionHandlerService) {

    this.actionHandler.floatButtonAction.next({
      type: 'close', callback: () => {
        this.router.navigate(['/profile'], {queryParamsHandling: 'merge'});
      }
    });

    this.dataService.countryPickerState.subscribe(value => {
      this.searchField = value.searchField;
      this.expandedPanel = value.expandedPanel;
    });

   /* breakpointObserver.observe([
      Breakpoints.HandsetLandscape
    ]).subscribe(result => {
      this.handsetLandscape = result.matches;
    });*/

    dataService.regions.subscribe(value => {
      this.regions = value;
      this.filteredRegions = value;
    });

    authService.localUserData$.subscribe(value => {
      if (value) {
        this.homeCountry = value.homeCountry;

        if (value.visitedCountries) {
          this.visitedCountries = value.visitedCountries.map(c => c.iso3);
        }
      }
    });
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      // console.log("params.get(\"homeCountry\") === \"true\"");
      // console.log(params.get("homeCountry") === "true");
      this.isHomeCountryPicker = params.get('homeCountry') === 'true';
    });
  }

  scratchCountry(country: Country) {
    this.dataService.focusToCountry(country.iso3);
    const currentUser = this.authService.localUserData$.getValue();
    currentUser.visitedCountries.push({iso3: country.iso3, notes: '', name: country.name});
    this.authService.updateLocalUserData(currentUser);
  }

  editCountryDetails(country: Country) {
    this.dataService.countryPickerState.next({
      searchField: this.searchField,
      expandedPanel: this.getExpandedPanel(country)
    });
    this.router.navigate(['/country-details-editor',
      {
        iso3: country.iso3,
        edit: true,
        visited: this.visitedCountries.includes(country.iso3)
      }
        ], {queryParamsHandling: 'merge'});
  }

  deleteCountry(country: Country) {
    this.dialog.open(ConfirmationModalComponent, {
      data:
        {
          title: `Unscratch ${country.name}`,
          description: `Are you sure you want to remove ${country.name}? <br/>All notes will be permanently deleted.`,
          actionText: 'Unscratch',
          actionIcon: 'delete'
        }
    }).afterClosed().subscribe((confirmationResult) => {
      if (confirmationResult) {
        this.authService.deleteUserCountry(country.iso3);
      }
    });
    // this.router.navigate(["/country-details-editor", {iso3: country.iso3, edit: true}]);
  }

  private getExpandedPanel(country: Country): string {
    return Object.entries(this.dataService.regions.getValue())
      .find((countries) => countries[1].includes(country)).shift();
  }

  setHomeCountry(country: Country) {
    const currentUser = this.authService.localUserData$.getValue();
    if (currentUser.homeCountry) {
      const index = currentUser.visitedCountries.findIndex(value => value.iso3 === currentUser.homeCountry);
      if (index > -1) {
        currentUser.visitedCountries.splice(index, 1);
      }
    }
    currentUser.homeCountry = country.iso3;
    currentUser.visitedCountries.push({iso3: country.iso3, notes: '', name: country.name});
    this.authService.updateLocalUserData(currentUser);
  }

  resetHomeCountry() {
    const currentUser = this.authService.localUserData$.getValue();
    const index = currentUser.visitedCountries.findIndex(value => value.iso3 === currentUser.homeCountry);
    if (index > -1) {
      currentUser.visitedCountries.splice(index, 1);
    }
    currentUser.homeCountry = null;
    this.authService.updateLocalUserData(currentUser);
  }
}
