import { Component, OnInit } from '@angular/core';
import {AuthService} from '../services/auth.service';
import {Achievement, AchievementInfo, achievementsInfoList, User} from '../model/user.model';
import {ActionHandlerService} from '../services/action-handler.service';
import {Router} from '@angular/router';
import {BreakpointObserver} from '@angular/cdk/layout';
import {AchievementModalComponent} from '../achievement-modal/achievement-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {LayoutConstant} from '../model/layout-constant';

@Component({
  selector: 'app-all-achievements',
  templateUrl: './all-achievements.component.html',
  styleUrls: ['./all-achievements.component.scss']
})
export class AllAchievementsComponent implements OnInit {
  layout = LayoutConstant;
  allAchievsCount: number;
  achievementsInfoList = achievementsInfoList;
  rowHeight: string;
  groupedAchievements: Array<Array<AchievementInfo>> = new Array<Array<AchievementInfo>>();

  constructor(public auth: AuthService,
              private router: Router,
              public dialog: MatDialog,
              breakpointObserver: BreakpointObserver,
              private actionHandler: ActionHandlerService) {
    this.achievementsInfoList.forEach((value, index) => {
      if (index % 3 === 0) {
        this.groupedAchievements.push([value]);
      } else {
        this.groupedAchievements[this.groupedAchievements.length - 1].push(value);
      }
    });

    this.allAchievsCount = Object.keys(Achievement).length;

    let nextAction = this.actionHandler.getNextAction();
    if (!nextAction) {
      nextAction = this.actionHandler.defaultCloseAction;
    }
    this.actionHandler.floatButtonAction.next(nextAction);


    breakpointObserver
      .observe(['(max-width: 1023px)'])
      .subscribe(result => {
        this.rowHeight = result.matches ? '150px' : '180px';
      });
  }

  ngOnInit(): void {
  }

  isNotUserAchievement(user: User, key: string) {
    return !user.achievements.includes(Achievement[key]);
  }

  viewDetails(user: User, key: string) {
    this.dialog.open(AchievementModalComponent, {
      data: {
        achievement: Achievement[key],
        isUnlocked: user.achievements.includes(Achievement[key])
      }
    });
  }
}
