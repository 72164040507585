import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {UserProfileComponent} from './user-profile/user-profile.component';
import {UserLoginComponent} from './user-login/user-login.component';
import {AuthGuard} from './auth.guard';
import {SignupUserComponent} from './signup-user/signup-user.component';
import {PasswordResetComponent} from './password-reset/password-reset.component';
import {InitPasswordResetComponent} from './init-password-reset/init-password-reset.component';
import {CountryPickerComponent} from './country-picker/country-picker.component';
import {CountryDetailsEditorComponent} from './country-details-editor/country-details-editor.component';
import {AllAchievementsComponent} from './all-achievements/all-achievements.component';
import {AllCountriesComponent} from './all-countries/all-countries.component';
import {MenuComponent} from './menu/menu.component';
import {ProfileSettingsComponent} from './profile-settings/profile-settings.component';
import {UpdatePasswordComponent} from './update-password/update-password.component';
import {FriendProfileComponent} from './friend-profile/friend-profile.component';
import {FeedbackComponent} from './feedback/feedback.component';

const routes: Routes = [
  {
    path: 'login',
    component: UserLoginComponent
  },
  {
    path: 'signup',
    component: SignupUserComponent
  },
  {
    path: 'friend-profile',
    component: FriendProfileComponent,
  },
  {
    path: 'profile',
    component: UserProfileComponent,
  },
  {
    path: 'auth/password-reset',
    component: PasswordResetComponent
  },
  {
    path: 'init-password-reset',
    component: InitPasswordResetComponent
  },
  {
    path: 'country-picker',
    component: CountryPickerComponent
  },
  {
    path: 'country-details-editor',
    component: CountryDetailsEditorComponent,
  },
  {
    path: 'all-achievements',
    component: AllAchievementsComponent,
  },
  {
    path: 'all-countries',
    component: AllCountriesComponent,
  },
  {
    path: 'feedback',
    component: FeedbackComponent,
  },
  {
    path: 'menu',
    component: MenuComponent,
    /*canActivate: [AuthGuard]*/
  },
  {
    path: 'profile-setts',
    component: ProfileSettingsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'change-password',
    component: UpdatePasswordComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: '/profile'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
