import {Component, OnInit} from '@angular/core';
import {LayoutConstant} from '../model/layout-constant';
import {FeedbackService} from '../services/feedback.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  layout = LayoutConstant;
  feedback: string;

  constructor(private feedbackService: FeedbackService) {
  }

  ngOnInit(): void {
  }

  submitFeedback(feedback: string) {
    this.feedbackService.addFeedback(feedback);
  }
}
