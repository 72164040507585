<button mat-icon-button routerLink="/menu">
  <mat-icon class="secondary-text-color">menu</mat-icon>
</button>
<div class="block-top-margin">
  <span class="title" fxLayoutAlign="center center">Password recovery</span>
  <span class="inner-block-top-margin-l h6 secondary-text-color"
        fxLayoutAlign="center center">Please fill in the new password to continue</span>
</div>

<form fxLayout="column"
      class="block-top-margin"
      [fxLayoutGap]="layout.M"
      [formGroup]="savePasswordForm"
      (ngSubmit)="savePassword()">
  <mat-form-field class="no-bottom-margin">
    <input matInput
           placeholder="New password"
           [type]="hidePassword ? 'password' : 'text'"
           [formControl]="savePasswordForm.controls.password">
    <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hidePassword">
      <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
    </button>
    <mat-error *ngIf="savePasswordForm.controls.password.hasError('required')">
      Password is <strong>required</strong>
    </mat-error>
    <mat-error *ngIf="savePasswordForm.controls.password.hasError('minlength')">
      Password must be at least <strong>8</strong> characters long
    </mat-error>
    <mat-error *ngIf="savePasswordForm.controls.password.hasError('pattern')">
      Password must contains at least 1 number
    </mat-error>
  </mat-form-field>
  <div fxLayout="column" class="block-top-margin">
    <button [disabled]="!savePasswordForm.valid"
            type="submit"
            class="rounded-btn"
            mat-flat-button
            color="primary">Save new password</button>
  </div>
</form>



