<button mat-icon-button routerLink="/menu">
    <mat-icon class="secondary-text-color">menu</mat-icon>
</button>

<div class="block-top-margin">
    <span class="title" fxLayoutAlign="center center">Sign in</span>

    <div fxLayoutAlign="center center" class="inner-block-top-margin-l h6">
        <span class="secondary-text-color">Do not have an account?</span>&nbsp;
        <app-primary-link [innerText]="'Create account'" routerLink="/signup"></app-primary-link>
    </div>
</div>

<div fxLayout="row" fxLayout.lt-lg="column" [fxLayoutGap]="layout.M" class="block-top-margin">
    <button class="rounded-btn"
            mat-flat-button
            fxFlex="100"
            color="accent"
            (click)="login(auth.authProviders.GOOGLE)">
        <mat-icon svgIcon="google"></mat-icon> Continue with Google
    </button>
</div>
<div fxLayout="row" fxLayout.lt-lg="column" [fxLayoutGap]="layout.M" class="block-top-margin">
    <button class="rounded-btn"
            mat-flat-button
            fxFlex="100"
            color="accent"
            (click)="login(auth.authProviders.FACEBOOK)">
        <mat-icon svgIcon="facebook"></mat-icon> Continue with Facebook
    </button>
</div>

<span class="block-top-margin secondary-text-color" fxLayoutAlign="center center">Or sign in with email</span>

<form fxLayout="column"
      [fxLayoutGap]="layout.M"
      class="block-top-margin"
      [formGroup]="loginForm"
      (ngSubmit)="emailLogin()">
  <mat-form-field class="no-bottom-margin">
    <input matInput
           placeholder="Email"
           [formControl]="loginForm.controls.email">
    <mat-error *ngIf="loginForm.controls.email.hasError('email') && !loginForm.controls.email.hasError('required')">
      Please enter a valid email address
    </mat-error>
    <mat-error *ngIf="loginForm.controls.email.hasError('required')">
      Email is <strong>required</strong>
    </mat-error>
  </mat-form-field>
  <mat-form-field class="no-bottom-margin">
    <input matInput
           placeholder="Password"
           [type]="hidePassword ? 'password' : 'text'"
           [formControl]="loginForm.controls.password">
    <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hidePassword">
      <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
    </button>
    <mat-error *ngIf="loginForm.controls.password.hasError('required')">
      Password is <strong>required</strong>
    </mat-error>
    <mat-error *ngIf="loginForm.controls.password.hasError('minlength') && !loginForm.controls.password.hasError('required')">
      Password must be at least <strong>8</strong> characters long
    </mat-error>
    <mat-error *ngIf="loginForm.controls.password.hasError('pattern') && !loginForm.controls.password.hasError('required') && !loginForm.controls.password.hasError('minlength')">
      Password must contains at least 1 number
    </mat-error>
  </mat-form-field>
  <app-primary-link [innerText]="'Forgot password?'" routerLink="/init-password-reset" class="no-bottom-margin"></app-primary-link>
  <div fxLayout="column" class="block-top-margin">
    <button [disabled]="!loginForm.valid"
            type="submit"
            class="rounded-btn"
            mat-flat-button
            color="primary">Sign in
    </button>
  </div>
</form>



