import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import 'hammerjs';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {ESMMaterialModule} from './material-module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MapComponent} from './map/map.component';

import {AngularFireModule} from '@angular/fire';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {UserProfileComponent} from './user-profile/user-profile.component';
import {UserLoginComponent} from './user-login/user-login.component';
import {SignupUserComponent} from './signup-user/signup-user.component';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PasswordResetComponent} from './password-reset/password-reset.component';
import {InitPasswordResetComponent} from './init-password-reset/init-password-reset.component';
import {CountryPickerComponent} from './country-picker/country-picker.component';
import {AngularFireDatabaseModule} from '@angular/fire/database';
import {CountryPickerModalComponent} from './country-picker-modal/country-picker-modal.component';
import {CountryFilterPipe} from './country-filter.pipe';
import {HttpClientModule} from '@angular/common/http';
import {CountryDetailsEditorComponent} from './country-details-editor/country-details-editor.component';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {ConfirmationModalComponent} from './confirmation-modal/confirmation-modal.component';
import {TextLineBreakerPipe} from './text-line-breaker.pipe';
import {CustomBreakPointsProvider} from './custom-layout/custom-breakpoints';
import {ExpandableDivComponent} from './expandable-div/expandable-div.component';
import {AngularResizedEventModule} from 'angular-resize-event';
import {DecimalPipe} from '@angular/common';
import {AllCountriesComponent} from './all-countries/all-countries.component';
import {AllAchievementsComponent} from './all-achievements/all-achievements.component';
import {NewAchievementComponent} from './new-achievement/new-achievement.component';
import {AchievementModalComponent} from './achievement-modal/achievement-modal.component';
import {MenuComponent} from './menu/menu.component';
import {ProfileSettingsComponent} from './profile-settings/profile-settings.component';
import {UpdatePasswordComponent} from './update-password/update-password.component';
import {PrimaryLinkComponent} from './primary-link/primary-link.component';
import {FriendProfileComponent} from './friend-profile/friend-profile.component';
import {ForNumberPipe} from './for-number.pipe';
import {ActionItemWithIconComponent} from './action-item-with-icon/action-item-with-icon.component';
import {ButtonContentComponent} from './button-content/button-content.component';
import {FeedbackComponent} from './feedback/feedback.component';
import {NotificationMessageComponent} from './notification-message/notification-message.component';

import {AchievementComponent} from './components/achievement.component';
import {DummyAchievementComponent} from './components/dummy-achievement.component';

const config = {
  apiKey: 'AIzaSyAKXfdfLTOsz2SNtvBPdrE0mR7S33Df2Lk',
  authDomain: 'escratchmap.firebaseapp.com',
  databaseURL: 'https://escratchmap.firebaseio.com',
  projectId: 'escratchmap',
  storageBucket: 'escratchmap.appspot.com',
  messagingSenderId: '29480551612',
  appId: '1:29480551612:web:61483df04c5341af96fcb9',
  measurementId: 'G-YE8NNZRV0P'
};

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [
    AppComponent,
    MapComponent,
    UserProfileComponent,
    UserLoginComponent,
    SignupUserComponent,
    PasswordResetComponent,
    InitPasswordResetComponent,
    CountryPickerComponent,
    CountryPickerModalComponent,
    CountryFilterPipe,
    CountryDetailsEditorComponent,
    ConfirmationModalComponent,
    TextLineBreakerPipe,
    ExpandableDivComponent,
    AllCountriesComponent,
    AllAchievementsComponent,
    NewAchievementComponent,
    AchievementModalComponent,
    MenuComponent,
    ProfileSettingsComponent,
    UpdatePasswordComponent,
    PrimaryLinkComponent,
    FriendProfileComponent,
    ForNumberPipe,
    ActionItemWithIconComponent,
    ButtonContentComponent,
    FeedbackComponent,
    NotificationMessageComponent,
    AchievementComponent,
    DummyAchievementComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ESMMaterialModule,
    FlexLayoutModule,
    AngularFireModule.initializeApp(config),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    PerfectScrollbarModule,
    FlexLayoutModule.withConfig({disableDefaultBps: true, addOrientationBps: true}),
    AngularResizedEventModule
  ],
  entryComponents: [
    CountryPickerModalComponent,
    ConfirmationModalComponent
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: Window,
      useValue: window
    },
    CustomBreakPointsProvider,
    DecimalPipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
