import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {switchMap} from 'rxjs/operators';
import {AuthService} from '../services/auth.service';
import {Observable, of, Subscription} from 'rxjs';
import {DataService} from '../services/data.service';
import {ActionHandlerService, EventType} from '../services/action-handler.service';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmationModalComponent} from '../confirmation-modal/confirmation-modal.component';
import {LayoutConstant} from '../model/layout-constant';

interface CountryDetails {
  iso3: string;
  notes: string;
  name: string;
  visited?: boolean;
  edit: boolean;
}

@Component({
  selector: 'app-country-details-editor',
  templateUrl: './country-details-editor.component.html',
  styleUrls: ['./country-details-editor.component.scss']
})
export class CountryDetailsEditorComponent implements OnInit, OnDestroy {

  layout = LayoutConstant;
  country: Observable<CountryDetails>;
  canBeSave: boolean;
  private countrySubscription: Subscription;

  constructor(private route: ActivatedRoute,
              public auth: AuthService,
              public data: DataService,
              public dialog: MatDialog,
              private actionHandler: ActionHandlerService,
              public router: Router) {

    let nextAction = this.actionHandler.getNextAction();
    if (!nextAction) {
      nextAction = {
        type: 'close',
        callback: () => this.router.navigate(['country-picker'], {queryParamsHandling: 'merge'})
      };
    }
    this.actionHandler.floatButtonAction.next(nextAction);

    this.actionHandler.eventDispatcher.subscribe(value => {
      if (value === EventType.COUNTRY_WAS_REMOVED) {
        this.actionHandler.floatButtonAction.getValue().callback();
      }
    });
  }

  ngOnInit() {
    this.data.countryList.subscribe(countryList => {
        if (countryList) {
          this.country = this.route.paramMap.pipe(
            switchMap((params: ParamMap) => {
              const iso3 = params.get('iso3');
              const edit = params.get('edit') === 'true';
              const countryData = countryList.find(c => c.iso3 === iso3);
              if (edit) {
                const visitedCountry = this.auth.localUserData$.getValue().visitedCountries.find(c => c.iso3 === iso3);
                return of<CountryDetails>({
                  name: countryData.name,
                  iso3,
                  notes: visitedCountry ? visitedCountry.notes : '',
                  edit,
                  visited: params.get('visited') === 'true'
                });
              } else {
                return of<CountryDetails>({
                  name: countryData.name,
                  iso3,
                  notes: '',
                  edit,
                  visited: params.get('visited') === 'true'
                });
              }
            })
          );

          this.countrySubscription = this.country.subscribe(value => {
            this.data.focusToCountry(value.iso3);
          });
        }
      }
    );
  }

  onCancelClick() {
    this.actionHandler.floatButtonAction.getValue().callback();
  }

  onScratchClick(country: CountryDetails) {
    const currentUser = this.auth.localUserData$.getValue();
    currentUser.visitedCountries.push({iso3: country.iso3, notes: country.notes, name: country.name});
    this.auth.updateLocalUserData(currentUser);

    this.country = of<CountryDetails>({
      name: country.name,
      iso3: country.iso3,
      notes: country.notes,
      visited: true,
      edit: true
    });
  }

  onDeleteCountry(country: CountryDetails) {
    this.dialog.open(ConfirmationModalComponent, {
      data:
        {
          title: `Unscratch ${country.name}`,
          description: `Are you sure you want to remove ${country.name}? <br/>All notes will be permanently deleted.`,
          actionText: 'Unscratch',
          actionIcon: 'delete'
        }
    }).afterClosed().subscribe((confirmationResult) => {
      if (confirmationResult) {
        this.auth.deleteUserCountry(country.iso3);
        this.country = of<CountryDetails>({
          name: country.name,
          iso3: country.iso3,
          notes: '',
          edit: false,
          visited: false
        });
      }
    });
  }

  onSaveCountry(country: CountryDetails) {
    const currentUser = this.auth.localUserData$.getValue();
    currentUser.visitedCountries.find(value => value.iso3 === country.iso3).notes = country.notes;
    this.auth.updateLocalUserData(currentUser);

    this.actionHandler.floatButtonAction.getValue().callback();

  }

  ngOnDestroy(): void {
    this.countrySubscription.unsubscribe();
  }
}
