import {Component, Inject, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {AchievementModalComponent} from '../achievement-modal/achievement-modal.component';

@Component({
  selector: 'app-new-achievement',
  templateUrl: './new-achievement.component.html',
  styleUrls: ['./new-achievement.component.scss']
})
export class NewAchievementComponent implements OnInit {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any,
              public dialog: MatDialog,
              public snackBarRef: MatSnackBarRef<NewAchievementComponent>) {
  }

  ngOnInit(): void {
  }

  seeDetails() {
    this.snackBarRef.dismiss();
    this.dialog.open(AchievementModalComponent, {
      data: {
        achievement: this.data.achievement,
        isUnlocked: true
      }
    });
  }
}
