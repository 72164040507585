import {Component, OnDestroy, OnInit} from '@angular/core';
import {LayoutConstant} from '../model/layout-constant';
import {Country} from '../model/country';
import {combineLatest, Subscription} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {ActionHandlerService} from '../services/action-handler.service';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {DataService} from '../services/data.service';
import {MatDialog} from '@angular/material/dialog';
import {BreakpointObserver} from '@angular/cdk/layout';
import {BR_QUERY} from '../custom-layout/custom-breakpoints';
import {Achievement, achievementsInfoList, User} from '../model/user.model';
import {AchievementModalComponent} from '../achievement-modal/achievement-modal.component';
import {NavigationService} from '../services/navigation.service';

@Component({
  selector: 'app-friend-profile',
  templateUrl: './friend-profile.component.html',
  styleUrls: ['./friend-profile.component.scss']
})
export class FriendProfileComponent implements OnInit, OnDestroy {
  isSmallScreen: boolean;
  layout = LayoutConstant;

  homeCountryISO3: string;
  homeCountry: Country = null;
  countryList: Array<Country>;
  private userSubscription: Subscription;
  private regionsSubscription: Subscription;

  constructor(public auth: AuthService,
              public actionHandler: ActionHandlerService,
              public router: Router,
              public dataService: DataService,
              public dialog: MatDialog,
              private route: ActivatedRoute,
              public navigation: NavigationService,
              breakpointObserver: BreakpointObserver) {
    this.actionHandler.floatButtonAction.next(undefined);
    breakpointObserver
      .observe([BR_QUERY.LTLG])
      .subscribe(result => {
        this.isSmallScreen = result.matches;
      });

    this.route.queryParamMap.subscribe((params: ParamMap) => {
      this.auth.readOnlyData.next(params.get('id') !== undefined);
      const friendId = params.get('id');
      if (friendId) {
        // this.auth.cachedLocalUserData$.next(this.auth.localUserData$.getValue());
        dataService.getFriendData(friendId).subscribe(friendProfile => {
          this.auth.setFriendUserData(friendProfile);
        });
      }
    });

    combineLatest([this.dataService.countryList, this.auth.localUserData$]).subscribe(value => {
      const countryList = value[0];
      const user = value[1];

      if (countryList && user) {
        this.countryList = countryList;
        this.homeCountryISO3 = user.homeCountry;
        this.updateHomeCountry();
      }
    });

    /*this.regionsSubscription = this.dataService.regions.subscribe(value => {
      if (value) {
        this.regions = value;
        this.updateHomeCountry();
      }
    });
    this.userSubscription = this.auth.localUserData$.subscribe(value => {
      if (value) {
        this.homeCountryISO3 = value.homeCountry;
        this.updateHomeCountry();
      }
    });*/


  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {

  }

  private updateHomeCountry() {
    if (this.countryList) {
      this.homeCountry = this.countryList.find(country => country.iso3 === this.homeCountryISO3);
    }
  }

  /*  navigateToCountryPicker() {
      let currentState = this.dataService.countryPickerState.getValue();
      currentState.searchField = this.homeCountry ? this.homeCountry.name : '';
      this.dataService.countryPickerState.next(currentState);

      this.router.navigate(['country-picker', {homeCountry: true}])
    }*/

  navigateTo(path: string): void {
    this.actionHandler.setNextAction({
      type: 'close',
      callback: () => this.router.navigate(['friend-profile'], {queryParams: this.route.snapshot.queryParams, queryParamsHandling: 'merge'})
    });
    this.router.navigate([path], {queryParams: this.route.snapshot.queryParams, queryParamsHandling: 'merge'});
  }

  viewAchievementDetails(achievement: Achievement) {
    this.dialog.open(AchievementModalComponent, {
      data: {
        achievement,
        isUnlocked: true
      }
    });
  }

  getAchievementDescription(achievement: Achievement) {
    return achievementsInfoList.find(value => value.achievement === achievement).description;
  }

  dummyAchievements(user: User) {
    return (this.isSmallScreen ? 4 : 5) - user.achievements.length > 0
      ? achievementsInfoList
        .filter(aInfo => !user.achievements.includes(aInfo.achievement))
        .slice(0, (this.isSmallScreen ? 4 : 5) - user.achievements.length)
      : [];
  }

  dummyCountries(user: User) {
    return this.countryList
    && (this.isSmallScreen ? 4 : 5) - user.visitedCountries.length > 0
      ? this.countryList
        .filter(country => !user.visitedCountries.map(cInfo => cInfo.iso3).includes(country.iso3))
        .slice(0, (this.isSmallScreen ? 4 : 5) - user.visitedCountries.length)
      : [];
  }
}
