import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Achievement, User} from '../model/user.model';
import {auth} from 'firebase/app';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFirestore} from '@angular/fire/firestore';

import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {UserEmailLogin} from '../model/user-email-login.model';
import {AppError} from '../model/app-error';

import {err, ok, Result} from 'neverthrow';
import {ErrorCodeMapperUtil} from '../error-handler/error-code-mapper-util';
import {AchievementService} from './achievement.service';
import {DataService} from './data.service';
import {StatisticsService} from './statistics.service';
import {NotificationsService} from './notifications.service';

export type ExecResult<T> = Result<T, AppError>;

export enum AuthProvider {
  GOOGLE, FACEBOOK
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user$: Observable<User>;

  localUserData$: BehaviorSubject<User> = new BehaviorSubject(undefined);

  cachedLocalUserData$: BehaviorSubject<User> = new BehaviorSubject<User>(null);

  readOnlyData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  authProviders = AuthProvider;

  errorMapper = new ErrorCodeMapperUtil();

  VOID_USER = {
    visitedCountries: [],
    achievements: [],
    email: '',
    uid: null,
    displayName: 'Hello, Guest!',
    homeCountry: '',
    photoURL: '',
    isAnonymous: true
  };

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    private route: ActivatedRoute,
    private achievementService: AchievementService,
    private statisticsService: StatisticsService,
    private dataService: DataService,
    private notificationService: NotificationsService) {

    this.route.queryParamMap.subscribe(params => {

      this.user$ = this.afAuth.authState.pipe(
        switchMap(user => {
          // Logged in
          if (user) {
            return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
          } else {
            // Logged out
            return of(this.anonymousUser());
          }
        })
      );

      combineLatest([this.dataService.regions, this.user$]).subscribe(value => {
        const user: User = value[1];
        const regions = value[0];
        if (user && regions) {
          if (this.readOnlyData.getValue()) {
            this.cachedLocalUserData$.next(user);
          } else {
            user.statistics = this.statisticsService.generate(user);
            if (user.welcome) {
              this.notificationService.showNewAchievements([Achievement.WELCOME]);
              if (!user.achievements) {
                user.achievements = [];
              }
              user.achievements.unshift(Achievement.WELCOME);
              user.welcome = false;
              this.updateLocalUserData(user);
            } else if (!user.achievements) {
              this.updateLocalUserData(user);
            } else {
              this.localUserData$.next(user);
            }
          }
        }
      });
    });
  }

  getAuth() {
    return this.afAuth.auth;
  }

  updateLocalUserData(user: User): Promise<void> {
    if (user) {
      this.updateAchievements(user);
    }

    if (!user.isAnonymous) {
      return this.afs.doc<User>(`users/${user.uid}`)
        .set(user, {merge: true})
        .then(() => {
          user.statistics = this.statisticsService.generate(user);
          this.localUserData$.next(user);
          this.cachedLocalUserData$.next(user);
        }).then(() => {
          const readOnlyUser = {...user, email: null};
          this.afs.doc<User>(`users-readonly/${readOnlyUser.readOnlyId}`)
            .set(readOnlyUser, {merge: true});
        });
    } else {
      user.statistics = this.statisticsService.generate(user);
      this.localUserData$.next(user);
      this.cachedLocalUserData$.next(user);
      return null;
    }
  }

  setFriendUserData(user: User) {
    this.localUserData$.next(user);
  }

  private updateAchievements(user: User) {
    const generatedAchievements = this.achievementService.generate(user);
    let newAchievements = new Array<Achievement>();
    if (user.achievements) {
      generatedAchievements.filter(ach => !user.achievements.includes(ach)).forEach(newAch => {
        newAchievements.push(newAch);
      });
    } else {
      newAchievements = generatedAchievements;
    }

    this.notificationService.showNewAchievements(newAchievements);

    if (user.achievements && user.achievements.includes(Achievement.WELCOME)) {
      user.achievements = generatedAchievements;
      user.achievements.unshift(Achievement.WELCOME);
    } else {
      user.achievements = generatedAchievements;
    }

  }

  deleteUserCountry(iso3: string) {
    const user = this.localUserData$.getValue();
    const index = user.visitedCountries.findIndex(value => value.iso3 === iso3);
    if (index > -1) {
      user.visitedCountries.splice(index, 1);
      this.updateLocalUserData(user);
    }
  }

  async providerSignin(authProvider: AuthProvider): Promise<ExecResult<object>> {
    let result: ExecResult<object> = ok({});
    let currentProvider: firebase.auth.AuthProvider;
    switch (authProvider) {
      case AuthProvider.GOOGLE:
        currentProvider = new auth.GoogleAuthProvider();
        break;
      case AuthProvider.FACEBOOK:
        currentProvider = new auth.FacebookAuthProvider();
    }

    await this.afAuth.auth.signInWithPopup(currentProvider)
      .then(value => {
        if (value.additionalUserInfo.isNewUser) {
          const newUser: User = {...value.user};
          const localUser = this.localUserData$.getValue();

          this.fillNewUserWithAnonymousData(localUser, newUser);

          this.updateNewUserData(newUser, value.additionalUserInfo.providerId);
        }
      }).catch(error => {
        // Handle Errors here.
        result = err(this.errorMapper.mapErrorCode(error.code, error.message));
      });

    return result;
  }

  async emailSignin({email, password}: UserEmailLogin): Promise<ExecResult<object>> {
    let result: ExecResult<object> = ok({});
    await this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .catch(error => {
        // Handle Errors here.
        result = err(this.errorMapper.mapErrorCode(error.code, error.message));
      });
    return result;
  }

  async emailSignup({email, password, name}: UserEmailLogin): Promise<ExecResult<object>> {
    let result: ExecResult<object> = ok({});
    await this.afAuth.auth.createUserWithEmailAndPassword(email, password)
      .then(value => {
        const localUser = this.localUserData$.getValue();
        const newUser: User = {...value.user, displayName: name};
        this.fillNewUserWithAnonymousData(localUser, newUser);
        this.updateNewUserData(newUser, value.additionalUserInfo.providerId);
      })
      .catch(error => {
        // Handle Errors here.
        result = err(this.errorMapper.mapErrorCode(error.code, error.message));
      });
    return result;
  }

  private fillNewUserWithAnonymousData(localUser: User, newUser: User) {
    if (localUser && localUser.isAnonymous) {
      newUser.visitedCountries = localUser.visitedCountries;
      newUser.achievements = localUser.achievements;
      newUser.statistics = localUser.statistics;
      newUser.homeCountry = localUser.homeCountry;
    }

    if (newUser) {
      newUser.achievements.unshift(Achievement.WELCOME);
      newUser.welcome = true;
    }
  }

  async updatePassword(newPassword: string) {
    return this.afAuth.auth.currentUser.updatePassword(newPassword);
  }

  async deleteAccount() {
    return this.afAuth.auth.currentUser.delete()
      .then(value => {
        this.resetUserData();
        this.router.navigate(['']);
      })
      .catch(reason => {

      });
  }

  async initializePasswordReset({email}) {
    let result: ExecResult<object> = ok({});
    await this.afAuth.auth.sendPasswordResetEmail(email).catch((error) => {
      result = err(this.errorMapper.mapErrorCode(error.code, error.message));
    });
    return result;
  }

  private updateNewUserData(user: User, providerId: string) {
    const data = {
      uid: user.uid,
      visitedCountries: user.visitedCountries,
      homeCountry: user.homeCountry,
      statistics: user.statistics,
      achievements: user.achievements,
      readOnlyId: `${user.displayName.toLowerCase().replace(/ /g, '-')}-${user.uid}`,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      welcome: user.welcome
    };
    return this.afs.doc(`users/${user.uid}`).set(data, {merge: true});
  }

  async signOut() {
    this.resetUserData();
    await this.afAuth.auth.signOut();
    await this.router.navigate(['anonymous']);
  }

  resetUserData() {
    this.updateLocalUserData(this.anonymousUser());
  }

  anonymousUser(): User {
    return JSON.parse(JSON.stringify(this.VOID_USER));
  }
}
