<span class="h4">{{data.title}}</span>
<div class="inner-block-top-margin-l">
  <span class="width-100 body-1" [innerHTML]="data.description"></span>
</div>
<div fxLayout="row" [fxLayoutGap]="layout.S" class="inner-block-top-margin-l">
  <button
    class="rounded-btn transparent-btn"
    mat-stroked-button
    [mat-dialog-close]=true>
    <app-button-content [icon]="data.actionIcon" textClass="action-1" [innerText]="data.actionText"></app-button-content>
  </button>
  <button
    mat-stroked-button
    class="rounded-btn transparent-btn" [mat-dialog-close]=false>
    <span class="action-1">{{data.closeText || 'Close'}}</span>
  </button>
</div>
