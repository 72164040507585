import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {ActionHandlerService} from '../services/action-handler.service';
import {AuthService} from '../services/auth.service';
import {User} from '../model/user.model';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  user: User;

  constructor(public auth: AuthService,
              private router: Router,
              private actionHandler: ActionHandlerService) {

    auth.localUserData$.subscribe(value => {
      this.user = value;
    });

    this.actionHandler.floatButtonAction.next({
      type: 'close', callback: () => {
        this.router.navigate(['/profile'], {queryParamsHandling: 'merge'});
      }
    });
  }

  ngOnInit(): void {
  }

  navigateTo(path: string): void {
    this.router.navigate([path], {queryParamsHandling: 'merge'});
  }
}
