import {Injectable} from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  ActivatedRoute
} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from './services/auth.service';
import {tap, map, take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService,
              private route: ActivatedRoute,
              private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.auth.user$.pipe(
      take(1),
      map(user => {
        const loggedIn = !!user;
        const id = next.queryParamMap.get('id');
        // console.log(`isLogged=${loggedIn}, id=${id}, next route=${next.url}, state=${state.url}`);
        if (!loggedIn) {
          if (id) {
            this.router.navigate(['friend-profile'], {queryParams: {id}, queryParamsHandling: 'merge'});
          }
          return false;
        } else {
          if (!id) {
            this.router.navigate([next.url[0].path], {queryParams: {id: user.readOnlyId}, queryParamsHandling: 'merge'});
            return false;
          } else if (id !== user.readOnlyId) {
            this.router.navigate(['friend-profile'], {queryParams: {id}, queryParamsHandling: 'merge'});
            return false;
          }
        }
        return loggedIn;
      })
    );
  }

}
