import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textLineBreaker'
})
export class TextLineBreakerPipe implements PipeTransform {

  transform(value: string, ...args: any[]): string {
    return value.replace(/(\\r\\n)|([\r\n])/gmi, '<br/>');
  }
}
