<div class="secondary-text-color">
  <mat-form-field ngClass.lt-lg="country-filter" class="match-parent">
    <input matInput
           [(ngModel)]="searchField">
    <mat-label>
      <mat-icon [inline]="true">search</mat-icon>&nbsp;Search
    </mat-label>
  </mat-form-field>
</div>
<div class="secondary-text-color">
  <perfect-scrollbar style="height: 88vh; padding-right: 12px;" [usePSClass]=true>
    <mat-accordion multi="{{searchField !=''}}" style="margin-right:0;">
      <mat-expansion-panel *ngFor="let region of dataService.regions | async | countryFilter:searchField | keyvalue"
                           expanded="{{searchField !='' || expandedPanel === region.key}}">
        <mat-expansion-panel-header>
          <mat-panel-title class="h4">
            {{region.key}}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-list>

          <mat-list-item *ngFor="let country of region.value; index as index"
                         (mouseenter)="showAction=country.iso3"
                         [ngClass]="{'selection-color' : showAction==country.iso3, 'expansion-bottom-line' : index < region.value.length - 1}"
                         (mouseleave)="showAction=''">
            <span class="h5 cp-country-color" [fxFlex]="100">{{showAction == country.iso3 && country.name.length > 15 ? country.name.substring(0, 15) + "..." : country.name}}</span>
            <mat-icon *ngIf="!isHomeCountryPicker && showAction !== country.iso3 && visitedCountries.includes(country.iso3)" class="green-color">done</mat-icon>
            <mat-icon *ngIf="isHomeCountryPicker && showAction !== country.iso3 && homeCountry === country.iso3">home</mat-icon>
            <span *ngIf="showAction==country.iso3">
              <span *ngIf="isHomeCountryPicker">
                <span *ngIf="country.iso3 === homeCountry then editHomeCountryBlock else selectHomeCountryBlock"
                      fxFlex="50" style="margin-left: auto !important;"></span>
                <ng-template #editHomeCountryBlock>
                  <button class="rounded-btn"
                          mat-flat-button
                          color="accent"
                          (click)="resetHomeCountry()">
                    <app-button-content innerText="Reset home country" textClass="action-1" icon="delete_outline"></app-button-content>
                  </button>
                </ng-template>
                <ng-template #selectHomeCountryBlock>
                  <div fxLayout="row" [fxLayoutGap]="layout.S">
                     <button class="rounded-btn"
                             mat-flat-button
                             color="accent"
                             (click)="setHomeCountry(country)">
                    <app-button-content innerText="Home country" textClass="action-1" icon="home"></app-button-content>
                  </button>
                  </div>
                </ng-template>
              </span>

              <span *ngIf="!isHomeCountryPicker">
                <span *ngIf="visitedCountries.includes(country.iso3) then editDetailsBlock else scratchItBlock"
                      fxFlex="50" style="margin-left: auto !important;"></span>
              <ng-template #editDetailsBlock>
                 <div fxLayout="row" [fxLayoutGap]="layout.XS">
                   <button class="rounded-btn transparent-btn"
                           (click)="editCountryDetails(country)"
                           mat-stroked-button>
                     <app-button-content innerText="Edit details" textClass="action-1"
                                                icon="edit"></app-button-content>
                  </button>
                  <button class="transparent-btn"
                          (click)="deleteCountry(country)"
                          mat-icon-button>
                     <mat-icon class="rnd-btn-icon">delete_outline</mat-icon>
                  </button>
                 </div>
                </ng-template>
                <ng-template #scratchItBlock>
                  <div fxLayout="row" [fxLayoutGap]="layout.XS">
                    <button class="rounded-btn"
                            mat-flat-button
                            color="accent"
                            (click)="scratchCountry(country)">
                            <app-button-content innerText="Scratch it!" textClass="action-1"
                                                       icon="done"></app-button-content>
                  </button>
                  </div>
                </ng-template>
              </span>
            </span>
          </mat-list-item>

        </mat-list>
      </mat-expansion-panel>
    </mat-accordion>
  </perfect-scrollbar>

</div>
