import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { NewAchievementComponent } from '../new-achievement/new-achievement.component';
import { Achievement } from '../model/user.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private _snackBar: MatSnackBar) { }

  async showNewAchievements(achievements: Array<Achievement>) {
    this.processMessageQueue([...achievements]);
  }

  private processMessageQueue(notificationQueue: Array<Achievement>): void {
    const achievement = notificationQueue.shift();

    if (achievement) {
      this.showAchievement(achievement).afterDismissed().subscribe(() => {
        this.processMessageQueue(notificationQueue);
      });
    }
  }

  private showAchievement(achievement: Achievement): MatSnackBarRef<NewAchievementComponent> {
    return this._snackBar.openFromComponent(NewAchievementComponent, {
      duration: 3000,
      data: {achievement},
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: 'notification'
    });
  }
}
