import {Component, Input} from '@angular/core';

@Component({
    selector: 'achievement',
    templateUrl: './achievement.component.html',
})

export class AchievementComponent {
    @Input() tooltip: string;
    @Input() achievement: string;
}
