import {AppErrorsType} from '../model/app-errors-type';
import {AppError} from '../model/app-error';

export class ErrorCodeMapperUtil {
  errorsMap = new Map<string, AppErrorsType>([
    ['auth/wrong-password', AppErrorsType.AUTH_WRONG_PASSWORD],
    ['auth/too-many-requests', AppErrorsType.AUTH_TOO_MANY_REQUESTS],
    ['auth/user-not-found', AppErrorsType.AUTH_USER_NOT_FOUND],
    ['auth/invalid-email', AppErrorsType.AUTH_INVALID_EMAIL],
    ['auth/user-disabled', AppErrorsType.AUTH_USER_DISABLED],
    ['auth/email-already-in-use', AppErrorsType.AUTH_EMAIL_ALREADY_IN_USE],
    ['auth/account-exists-with-different-credential', AppErrorsType.AUTH_ACCOUNT_EXIST_WITH_DIFFERENT_CREDENTIALS],
    ['auth/popup-blocked', AppErrorsType.AUTH_POPUP_BLOCKED],
  ]);

  public mapErrorCode(code: string, message: string): AppError {
    return new AppError(this.errorsMap.get(code), message);
  }
}
