import {Injectable} from '@angular/core';
import {Achievement, User} from '../model/user.model';
import {DataService} from './data.service';

@Injectable({
  providedIn: 'root'
})
export class AchievementService {

  constructor(private dataService: DataService) { }

  static fillRegionAchievements(achievements: Array<Achievement>, key: string, allCountries: boolean) {
    switch (key) {
      case 'Africa':
        achievements.push(Achievement.AFRICA_OPENED);
        if (allCountries) {
          achievements.push(Achievement.AFRICA_DONE);
        }
        break;
      case 'Antarctic':
        achievements.push(Achievement.ANTARCTIC_OPENED);
        if (allCountries) {
          achievements.push(Achievement.ANTARCTIC_DONE);
        }
        break;
      case 'Asia':
        achievements.push(Achievement.ASIA_OPENED);
        if (allCountries) {
          achievements.push(Achievement.ASIA_DONE);
        }
        break;
      case 'Caribbean':
        achievements.push(Achievement.CARIBBEAN_OPENED);
        if (allCountries) {
          achievements.push(Achievement.CARIBBEAN_DONE);
        }
        break;
      case 'Central America':
        achievements.push(Achievement.CENTRAL_AMERICA_OPENED);
        if (allCountries) {
          achievements.push(Achievement.CENTRAL_AMERICA_DONE);
        }
        break;
      case 'Europe':
        achievements.push(Achievement.EUROPE_OPENED);
        if (allCountries) {
          achievements.push(Achievement.EUROPE_DONE);
        }
        break;
      case 'North America':
        achievements.push(Achievement.NORTH_AMERICA_OPENED);
        if (allCountries) {
          achievements.push(Achievement.NORTH_AMERICA_DONE);
        }
        break;
      case 'Oceania':
        achievements.push(Achievement.OCEANIA_OPENED);
        if (allCountries) {
          achievements.push(Achievement.OCEANIA_DONE);
        }
        break;
      case 'South America':
        achievements.push(Achievement.SOUTH_AMERICA_OPENED);
        if (allCountries) {
          achievements.push(Achievement.SOUTH_AMERICA_DONE);
        }
        break;
    }

  }

  private static fillVisitedCountries(achievements: Array<Achievement>, visitedCountries: Array<string>) {
    const count = visitedCountries.length;
    if (count >= 5) {
      achievements.push(Achievement.COUNTRIES5);
    }
    if (count >= 10) {
      achievements.push(Achievement.COUNTRIES10);
    }
    if (count >= 25) {
      achievements.push(Achievement.COUNTRIES25);
    }
    if (count >= 50) {
      achievements.push(Achievement.COUNTRIES50);
    }
    if (count >= 100) {
      achievements.push(Achievement.COUNTRIES100);
    }
  }

  generate(user: User) {
    const achievements = new Array<Achievement>();

    const regions = this.dataService.regions.getValue();
    if (regions && user.visitedCountries) {
      const visitedCountries = user.visitedCountries.map(value => value.iso3);
      AchievementService.fillVisitedCountries(achievements, visitedCountries);
      Object.keys(regions).forEach((key) => {
        const countries = regions[key].map(c => c.iso3);
        const matchedCountries = countries.filter(iso3 => visitedCountries.includes(iso3));
        if (matchedCountries.length > 0) {
          AchievementService.fillRegionAchievements(
            achievements,
            key,
            countries.length === matchedCountries.length
          );
        }
      });
    }

    return achievements;
  }
}
