<button mat-icon-button routerLink="/profile">
  <mat-icon class="secondary-text-color">home</mat-icon>
</button>

<div *ngIf="auth.localUserData$ | async as user">
  <!--User data-->
  <div class="block-top-margin">
    <div fxLayoutAlign="center center" class="inner-block-bottom-margin-m">
      <img class="avatar" [src]="user.photoURL || '../../assets/avatar_guest.png'">
    </div>
    <span class="title" fxLayoutAlign="center center">{{user.displayName}}</span>
    <div fxLayoutAlign="center center" class="inner-block-top-margin-s">
      <app-primary-link [innerText]="homeCountry ? homeCountry.name : 'No home country'" [icon]="'home'"></app-primary-link>
    </div>
  </div>
  <!--All flags-->
  <div class="block-top-margin">
    <span fxLayout="row">
      <span fxFlex class="secondary-text-color">
      {{user.visitedCountries.length}} flag{{user.visitedCountries.length > 1 || user.visitedCountries.length === 0 ? 's' : ''}} collected</span>
      <span fxFlex fxLayoutAlign="flex-end">
          <app-primary-link [innerText]="'See all'" [action]="navigateTo.bind(this, 'all-countries')"></app-primary-link>
      </span>
    </span>
    <div fxLayout="row" [fxLayoutGap]="isSmallScreen ? layout.M : layout.L" class="inner-block-top-margin-l">
      <div [fxFlex]="100 / (isSmallScreen ? 4 : 5)"
           *ngFor="let country of user.visitedCountries.slice(0, isSmallScreen ? 4 : 5)"
           (click)="dataService.focusToCountry(country.iso3)">
        <div class="square-container">
          <mat-icon [matTooltip]="country.name"  svgIcon="{{country.iso3}}" class="square-item flag"></mat-icon>
        </div>
      </div>
      <div [fxFlex]="100 / (isSmallScreen ? 4 : 5)"
           *ngFor="let dummyCountry of dummyCountries(user)"
           (click)="dataService.focusToCountry(dummyCountry.iso3)">
        <div class="square-container grayscale">
          <mat-icon [matTooltip]="dummyCountry.name"  svgIcon="{{dummyCountry.iso3}}" class="square-item flag"></mat-icon>
        </div>
      </div>
    </div>
  </div>
  <!--Achievements-->
  <div class="block-top-margin">
    <span fxLayout="row">
    <span fxFlex class="secondary-text-color">
    {{user.achievements.length}} achievement{{user.achievements.length > 1 || user.achievements.length === 0 ? 's' : ''}} unlocked</span>
    <span fxFlex fxLayoutAlign="flex-end">
      <app-primary-link [innerText]="'See all'" [action]="navigateTo.bind(this, 'all-achievements')"></app-primary-link>
    </span>
  </span>
    <div fxLayout="row" [fxLayoutGap]="isSmallScreen ? layout.M : layout.L" class="inner-block-top-margin-l">
        <div [fxFlex]="100 / (isSmallScreen ? 4 : 5)"
             *ngFor="let achievement of user.achievements.slice(0, isSmallScreen ? 4 : 5)"
             (click)="viewAchievementDetails(achievement)">
            <achievement [tooltip]="getAchievementDescription(achievement)"
                         [achievement]="achievement"></achievement>
        </div>
        <div [fxFlex]="100 / (isSmallScreen ? 4 : 5)"
             *ngFor="let dummyAchievement of dummyAchievements(user)">
            <dummy-achievement [tooltip]="dummyAchievement.title"></dummy-achievement>
        </div>
    </div>
  </div>
  <!--Statistics-->
  <div class="block-top-margin">
    <div>
      <span class="secondary-text-color">Your statistics</span>
    </div>
    <div fxLayout="row" [fxLayoutGap]="layout.M" class="inner-block-top-margin-l">
      <div fxFlex
           fxLayoutAlign="stretch"
           *ngFor="let stat of user.statistics">
        <div class="stat-div"
             fxLayout="column">
          <span class="kpi inner-block-bottom-margin-xs">{{stat.value}}</span>
          <span class="caption">{{stat.title}}</span>
        </div>
      </div>
    </div>
  </div>

</div>


