import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../services/auth.service';
import {Router} from '@angular/router';
import {ErrorHandlerService} from '../services/error-handler.service';
import {LayoutConstant} from '../model/layout-constant';

@Component({
  selector: 'app-init-password-reset',
  templateUrl: './init-password-reset.component.html',
  styleUrls: ['./init-password-reset.component.scss']
})
export class InitPasswordResetComponent implements OnInit {
  layout = LayoutConstant;

  resetPasswordForm = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.email,
    ])
  });

  constructor(public auth: AuthService, private router: Router, private errorHandler: ErrorHandlerService) { }

  ngOnInit() {
  }

  async resetPassword() {
    const resetPasswordResult = await this.auth.initializePasswordReset(this.resetPasswordForm.value);
    resetPasswordResult.mapErr(e => {
      this.errorHandler.showError(e.errorMessage.toString());
    });
    if (resetPasswordResult.isOk()) {
      await this.errorHandler.showError('Email with recovery link was sent to you. Please follow the instructions in the email.');
    }
  }
}
