import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-action-item-with-icon',
  templateUrl: './action-item-with-icon.component.html',
  styleUrls: ['./action-item-with-icon.component.scss']
})
export class ActionItemWithIconComponent implements OnInit {
  @Input() innerText = '';
  @Input() icon = '';
  @Input() swap = false;
  @Input() hideIcon = false;
  @Input() iconClass = '';
  @Input() textClass = 'h6';
  constructor() { }

  ngOnInit(): void {
  }

}
