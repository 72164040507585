import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {AuthService} from './auth.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NotificationMessageComponent} from '../notification-message/notification-message.component';
import {Router} from '@angular/router';

interface Feedback {
  name?: string;
  feedback: string;
  email?: string;
}

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  constructor(private afs: AngularFirestore,
              private authService: AuthService,
              private _snackBar: MatSnackBar,
              private router: Router) {
  }

  addFeedback(feedback: string) {
    const data: Feedback = {
      feedback
    };
    const userData = this.authService.localUserData$.getValue();
    if (userData) {
      data.name = userData.displayName;
      data.email = userData.email;
    }
    this.afs.collection<Feedback>(`feedback`).add(data)
      .then(() => {
        this._snackBar.openFromComponent(NotificationMessageComponent, {
          duration: 3000,
          data: {message: 'Feedback was sent successfully!'},
          horizontalPosition: 'end',
          verticalPosition: 'top',
          panelClass: 'notification'
        }).afterDismissed()
          .subscribe(value => {
          this.router.navigate(['/']);
        });
      });
  }
}
