<button mat-icon-button routerLink="/menu">
  <mat-icon class="secondary-text-color">menu</mat-icon>
</button>

<div class="block-top-margin">
  <span class="title" fxLayoutAlign="center center">Forgot password?</span>
  <span class="inner-block-top-margin-l h6 secondary-text-color"
        fxLayoutAlign="center center">That's ok. Please just tell us your email linked to account and we'll send you a recovery link</span>
</div>

<form fxLayout="column"
      class="block-top-margin"
      [fxLayoutGap]="layout.M"
      [formGroup]="resetPasswordForm"
      (ngSubmit)="resetPassword()">
  <mat-form-field class="no-bottom-margin">
    <input matInput
           placeholder="Email"
           [formControl]="resetPasswordForm.controls.email">
    <mat-error *ngIf="resetPasswordForm.controls.email.hasError('email') && !resetPasswordForm.controls.email.hasError('required')">
      Please enter a valid email address
    </mat-error>
    <mat-error *ngIf="resetPasswordForm.controls.email.hasError('required')">Email is <strong>required</strong>
    </mat-error>
  </mat-form-field>
  <div fxLayout="column" class="block-top-margin">
    <button [disabled]="!resetPasswordForm.valid"
            type="submit"
            class="rounded-btn"
            mat-flat-button
            color="primary">Send recovery email</button>
  </div>
</form>



