import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

export interface WindowSize {
  height: number;
  width: number;
}

@Injectable({
  providedIn: 'root'
})
export class WindowService {

  windowSize: BehaviorSubject<WindowSize> = new BehaviorSubject<WindowSize>({
    height: this.window.innerHeight,
    width: this.window.innerWidth
  });

  constructor(private window: Window) {

  }

  notifyWindowResize() {
    this.windowSize.next({
      height: this.window.innerHeight,
      width: this.window.innerWidth
    });
  }
}
