import { Injectable } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor(private _snackBar: MatSnackBar) { }

  async showError(message: string) {
    this._snackBar.open(message.toString(), 'Error', {duration: 5000});
  }
}
