import {CountryInfo} from './country-info.model';

export interface Stat {
  title: string;
  value: string;
}

export interface User {
  achievements?: Array<Achievement>;
  statistics?: Array<Stat>;
  uid: string;
  email: string;
  photoURL?: string;
  displayName?: string;
  homeCountry?: string;
  visitedCountries?: Array<CountryInfo>;
  notes?: string;
  providerId?: string;
  readOnlyId?: string;
  welcome?: boolean;
  isAnonymous?: boolean;
}

export enum Achievement {
  COUNTRIES5 = 'COUNTRIES5',
  COUNTRIES10 = 'COUNTRIES10',
  COUNTRIES25 = 'COUNTRIES25',
  COUNTRIES50 = 'COUNTRIES50',
  COUNTRIES100 = 'COUNTRIES100',
  AFRICA_DONE = 'AFRICA_DONE',
  AFRICA_OPENED = 'AFRICA_OPENED',
  ALL_COUNTRIES = 'ALL_COUNTRIES',
  ANTARCTIC_DONE = 'ANTARCTIC_DONE',
  ANTARCTIC_OPENED = 'ANTARCTIC_OPENED',
  ASIA_DONE = 'ASIA_DONE',
  ASIA_OPENED = 'ASIA_OPENED',
  CARIBBEAN_DONE = 'CARIBBEAN_DONE',
  CARIBBEAN_OPENED = 'CARIBBEAN_OPENED',
  CENTRAL_AMERICA_DONE = 'CENTRAL_AMERICA_DONE',
  CENTRAL_AMERICA_OPENED = 'CENTRAL_AMERICA_OPENED',
  NORTH_AMERICA_DONE = 'NORTH_AMERICA_DONE',
  NORTH_AMERICA_OPENED = 'NORTH_AMERICA_OPENED',
  SOUTH_AMERICA_DONE = 'SOUTH_AMERICA_DONE',
  SOUTH_AMERICA_OPENED = 'SOUTH_AMERICA_OPENED',
  EUROPE_DONE = 'EUROPE_DONE',
  EUROPE_OPENED = 'EUROPE_OPENED',
  OCEANIA_DONE = 'OCEANIA_DONE',
  OCEANIA_OPENED = 'OCEANIA_OPENED',
  PROFILE_FILLED = 'PROFILE_FILLED',
  WELCOME = 'WELCOME'
}

export interface AchievementInfo {
  achievement: Achievement;
  title: string;
  description: string;
}

const achievementsInfoList: Array<AchievementInfo> =
  [
    {
      achievement: Achievement.WELCOME,
      title: 'Welcome',
      description: 'For the registration in the best scratch map'
    },
    {
      achievement: Achievement.COUNTRIES5,
      title: '5 Countries',
      description: 'Good start! 5 countries scratched! Keep going!'
    },
    {
      achievement: Achievement.COUNTRIES10,
      title: '10 Countries',
      description: 'Not bad! 10 so far!'},
    {
      achievement: Achievement.COUNTRIES25,
      title: '25 Countries',
      description: '25! 25! Only now you can call yourself “a traveller”!'
    },
    {
      achievement: Achievement.COUNTRIES50,
      title: '50 Countries',
      description: '50 countries... Are you a pilot? Amazing!'
    },
    {
      achievement: Achievement.COUNTRIES100,
      title: '100 Countries',
      description: '100 countries! Unbelievable!'
    },
    {
      achievement: Achievement.ALL_COUNTRIES,
      title: 'All countries',
      description: 'THE WHOLE WORLD! To be honest we thought it was impossible! You are the best!'
    },
    {
      achievement: Achievement.AFRICA_DONE,
      title: 'Africa done',
      description: `Incredible! You have visited all 54 countries in Africa! Definitely something to tell to your
          grandchildren.`
    },
    {
      achievement: Achievement.AFRICA_OPENED,
      title: 'Africa opened',
      description: `You have discovered Africa. Did you visit Tolkien\'s house?  He was born in Bloemfontein,
          Orange Free State.`
    },
    {
      achievement: Achievement.ANTARCTIC_DONE,
      title: 'Antarctic done',
      description: 'Antarctic was fully discovered by you! Awesome!'},
    {
      achievement: Achievement.ANTARCTIC_OPENED,
      title: 'Antarctic opened',
      description: 'No way! Antarctic? ANTARCTIC! Are you one of the researchers?'},
    {
      achievement: Achievement.ASIA_DONE,
      title: 'Asia done',
      description: `Incredible! You have visited all countries in Asia! It is 48 countries. Which one do you think is
          the most interesting to visit?`
    },
    {
      achievement: Achievement.ASIA_OPENED,
      title: 'Asia opened',
      description: 'Nice! Asia! How was it? Did you learn kung-fu?'},
    {
      achievement: Achievement.CARIBBEAN_DONE,
      title: 'Caribbean done',
      description: 'Caribbean was fully discovered by you! Awesome!'},
    {
      achievement: Achievement.CARIBBEAN_OPENED,
      title: 'Caribbean opened',
      description: 'Nice! Caribbean was opened by you! How was it?'
    },
    {
      achievement: Achievement.CENTRAL_AMERICA_DONE,
      title: 'Central America done',
      description: `Central America is region of North America, lying between Mexico and South America and comprising
          Panama, Costa Rica, Nicaragua, Honduras, El Salvador, Guatemala, and Belize. And you have reached all of them.
          Well done!`
    },
    {
      achievement: Achievement.CENTRAL_AMERICA_OPENED,
      title: 'Central America opened',
      description: 'Nice! Central America. What do you like more República de Honduras or República de Costa Rica?'
    },
    {
      achievement: Achievement.NORTH_AMERICA_DONE,
      title: 'North America done',
      description: 'WOW! You have visited all countries in North America! Did you do it by car?'
    },
    {
      achievement: Achievement.NORTH_AMERICA_OPENED,
      title: 'North America opened',
      description: 'Nice! North America was opened by you! It was one of my dream to visit Silicon Valley.'
    },
    {
      achievement: Achievement.SOUTH_AMERICA_DONE,
      title: 'South America done',
      description: `Incredible! You have visited all countries in South America! Have you been to the carnival in Rio?
          I do.`
    },
    {
      achievement: Achievement.SOUTH_AMERICA_OPENED,
      title: 'South America opened',
      description: `Nice! South America was opened by you! The average annual temperatures in the Amazon basin
          oscillate around 27 °C (81 °F). Were you lucky enough to have such wonderful weather?`
    },
    {
      achievement: Achievement.EUROPE_DONE,
      title: 'Europe done',
      description: 'Europe was fully discovered by you! Awesome!'},
    {
      achievement: Achievement.EUROPE_OPENED,
      title: 'Europe opened',
      description: 'Nice! Europe was opened by you! How was it?'},
    {
      achievement: Achievement.OCEANIA_DONE,
      title: 'Oceania done',
      description: `Incredible! You have visited all countries in Oceania! It is a good time to share photos with your
          friends.`
    },
    {
      achievement: Achievement.OCEANIA_OPENED,
      title: 'Oceania opened',
      description: `Nice! Oceania was opened by you! I never knew before the start of that project that includes
          Australasia, Melanesia, Micronesia and Polynesia. Which one did you visit?`
    },
    {
      achievement: Achievement.PROFILE_FILLED,
      title: 'Profile filled',
      description: 'Some nice description'
    }
  ];


export {achievementsInfoList};


