<mat-list class="block-top-margin">
  <!--<mat-list-item (click)="navigateTo('/profile')">
    <mat-icon matListIcon>home</mat-icon>
    <span class="menu-item" matLine>Home</span>
  </mat-list-item>-->
  <mat-list-item (click)="navigateTo('profile-setts')">
   <!-- <mat-icon matListIcon>settings</mat-icon>-->
    <img matListIcon class="menu-avatar" [src]="(user && user.photoURL) || '../../assets/avatar_guest.png'">
    <span class="menu-item action-2" matLine>Profile settings</span>
  </mat-list-item>
  <mat-list-item>
    <mat-icon matListIcon>translate</mat-icon>
    <span class="menu-item action-2" matLine [matMenuTriggerFor]="translationMenu">
      <app-action-item-with-icon textClass="action-2" [swap]="true" icon="arrow_drop_down" innerText="English"></app-action-item-with-icon>
    </span>
    <mat-menu #translationMenu="matMenu">
      <button mat-menu-item>English</button>
      <button class="text-muted not-clickable" mat-menu-item>Nederlands</button>
      <button class="text-muted not-clickable" mat-menu-item>Русский</button>
      <button class="text-muted not-clickable" mat-menu-item>Українська</button>
    </mat-menu>
  </mat-list-item>
  <mat-list-item>
    <mat-icon matListIcon>brightness_1</mat-icon>
    <span class="menu-item action-2 text-muted not-clickable" matLine>Light theme</span>
  </mat-list-item>
  <mat-list-item (click)="navigateTo('feedback')">
    <mat-icon matListIcon>feedback</mat-icon>
    <span class="menu-item action-2" matLine>Leave feedback</span>
  </mat-list-item>
  <mat-divider *ngIf="!user.isAnonymous"></mat-divider>
  <mat-list-item *ngIf="!user.isAnonymous" (click)="auth.signOut()">
    <mat-icon matListIcon>highlight_off</mat-icon>
    <span class="menu-item action-2" matLine>Sign out</span>
  </mat-list-item>
</mat-list>
