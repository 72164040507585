<button mat-icon-button routerLink="/menu">
    <mat-icon class="secondary-text-color">menu</mat-icon>
</button>
<div class="block-top-margin">
    <span class="title" fxLayoutAlign="center center">Create account</span>

    <div fxLayoutAlign="center center" class="inner-block-top-margin-l h6">
        <span class="secondary-text-color">Already have an account?</span>&nbsp;
        <app-primary-link [innerText]="'Sign in'" routerLink="/login"></app-primary-link>
    </div>
</div>

<div fxLayout="row" fxLayout.lt-lg="column" [fxLayoutGap]="layout.M" class="block-top-margin">
    <button class="rounded-btn"
            mat-flat-button
            fxFlex="100"
            color="accent"
            (click)="signUp(auth.authProviders.GOOGLE)">
        <mat-icon svgIcon="google"></mat-icon> Continue with Google
    </button>
</div>
<div fxLayout="row" fxLayout.lt-lg="column" [fxLayoutGap]="layout.M" class="block-top-margin">
    <button class="rounded-btn"
            mat-flat-button
            fxFlex="100"
            color="accent"
            (click)="signUp(auth.authProviders.FACEBOOK)">
        <mat-icon svgIcon="facebook"></mat-icon> Continue with Facebook
    </button>
</div>

<span class="block-top-margin secondary-text-color" fxLayoutAlign="center center">Or continue with email</span>

<form fxLayout="column"
      class="block-top-margin"
      [fxLayoutGap]="layout.M"
      [formGroup]="registrationForm"
      (ngSubmit)="emailRegistration()">
  <mat-form-field class="no-bottom-margin">
    <input matInput
           placeholder="Name"
           [formControl]="registrationForm.controls.name">
  </mat-form-field>
  <mat-form-field class="no-bottom-margin">
    <input matInput
           placeholder="Email"
           [formControl]="registrationForm.controls.email">
    <mat-error *ngIf="registrationForm.controls.email.hasError('email') && !registrationForm.controls.email.hasError('required')">
      Please enter a valid email address
    </mat-error>
    <mat-error *ngIf="registrationForm.controls.email.hasError('required')">
      Email is <strong>required</strong>
    </mat-error>
  </mat-form-field>
  <mat-form-field class="no-bottom-margin">
    <input matInput
           placeholder="Password"
           [type]="hidePassword ? 'password' : 'text'"
           [formControl]="registrationForm.controls.password">
    <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hidePassword">
      <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
    </button>
    <mat-error *ngIf="registrationForm.controls.password.hasError('required')">
      Password is <strong>required</strong>
    </mat-error>
    <mat-error *ngIf="registrationForm.controls.password.hasError('minlength')">
      Password must be at least <strong>8</strong> characters long
    </mat-error>
    <mat-error *ngIf="registrationForm.controls.password.hasError('pattern')">
      Password must contains at least 1 number
    </mat-error>
  </mat-form-field>
  <div fxLayout="column" class="block-top-margin">
    <button [disabled]="!registrationForm.valid"
            type="submit"
            class="rounded-btn"
            mat-flat-button
            color="primary">Create account
    </button>
  </div>
</form>



