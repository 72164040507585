<div class="common-bottom-margin-lg relative-position">
  <div class="absolute-position">
    <button mat-icon-button routerLink="/menu">
      <mat-icon class="secondary-text-color">arrow_back</mat-icon>
    </button>
  </div>
  <div class="h4" fxLayoutAlign="center center">Feedback</div>
</div>
<div>
    <textarea matInput
              cdkTextareaAutosize
              placeholder="Fill in feedback"
              class="width-100"
              [(ngModel)]="feedback"></textarea>
  <div fxLayout="row" [fxLayoutGap]="layout.M" fxLayoutAlign="end" class="inner-block-top-margin-l">
    <button
      class="rounded-btn"
      mat-flat-button
      color="accent"
      (click)="submitFeedback(feedback)">
      <app-button-content innerText="Submit" textClass="action-1" icon="done"></app-button-content>
    </button>
  </div>

</div>
