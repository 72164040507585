export enum AppErrorsType {
  UNKNOWN_ERROR,
  INVALID_PASSWORD,
  AUTH_WRONG_PASSWORD,
  AUTH_TOO_MANY_REQUESTS,
  AUTH_USER_NOT_FOUND,
  AUTH_INVALID_EMAIL,
  AUTH_USER_DISABLED,
  AUTH_EMAIL_ALREADY_IN_USE,
  AUTH_ACCOUNT_EXIST_WITH_DIFFERENT_CREDENTIALS,
  AUTH_POPUP_BLOCKED

}
