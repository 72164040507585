import {Component, OnInit} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {Router} from '@angular/router';
import {ActionHandlerService} from '../services/action-handler.service';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;

  hidePassword = {
    password: true,
    repeatPassword: true
  };

  constructor(public auth: AuthService,
              private router: Router,
              private actionHandler: ActionHandlerService) {

    this.changePasswordForm = new FormGroup({
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern('.*[0-9].*')
      ]),
      repeatPassword: new FormControl('', [
        Validators.required,
        this.matchValues('password')
      ])
    });

    this.actionHandler.floatButtonAction.next({
      type: 'close', callback: () => {
        this.router.navigate(['/profile'], {queryParamsHandling: 'merge'});
      }
    });
  }

  public matchValues(matchTo: string): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent
      && !!control.parent.value
      && control.value === control.parent.controls[matchTo].value
        ? null
        : {passwordsNotEqual: true};
    };
  }

  ngOnInit(): void {
  }

  updatePassword() {
    this.auth.updatePassword(this.changePasswordForm.value.password).then(value => {
      this.changePasswordForm.clearValidators();
      this.router.navigate(['profile-setts'], {queryParamsHandling: 'merge'});
    });
  }

  cancelUpdatePassword() {
    this.changePasswordForm.clearValidators();
  }
}
