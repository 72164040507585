import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import {Router} from '@angular/router';
import {
  CountryPickerActionType,
  CountryPickerModalComponent
} from '../country-picker-modal/country-picker-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {DataService} from '../services/data.service';
import {AuthService} from '../services/auth.service';
import {User} from '../model/user.model';
import {Country} from '../model/country';
import {CountryInfo} from '../model/country-info.model';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {ActionHandlerService, EventType} from '../services/action-handler.service';
import {ConfirmationModalComponent} from '../confirmation-modal/confirmation-modal.component';
import {BehaviorSubject, combineLatest, Subscription} from 'rxjs';
import {WindowService} from '../services/window.service';

const BORDER_DATA = 'TM_WORLD_BORDERS-0-bjbzex';

@Component({
  selector: 'app-map',
  providers: [Location, {provide: LocationStrategy, useClass: PathLocationStrategy}],
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, AfterViewInit, OnDestroy {
  map: mapboxgl.Map;
  hoveredStateId = null;
  features: BehaviorSubject<Map<string, string>> = new BehaviorSubject<Map<string, string>>(undefined);
  private alreadyVisitedCountry: boolean;
  private user: User;
  private countries: Array<Country>;
  private visitedCountries: Array<CountryInfo> = new Array<CountryInfo>();

  constructor(public router: Router,
              public dialog: MatDialog,
              private dataService: DataService,
              private authService: AuthService,
              public windowService: WindowService,
              public actionService: ActionHandlerService) {

    combineLatest([this.authService.localUserData$, this.dataService.regions, this.features]).subscribe(observables => {
      if (observables.every(value => value)) {
        this.cleanVisitedCountries();
        this.user = this.authService.localUserData$.getValue();

        if (!this.user.visitedCountries) {
          this.user.visitedCountries = new Array<CountryInfo>();
        }

        this.visitedCountries = this.user.visitedCountries;
        this.fillWithUserData();
      }
    });

    this.windowService.windowSize.subscribe(value => {
      if (this.map) {
        this.map.resize();
      }
    });
  }

  cleanVisitedCountries() {
    this.features.getValue().forEach(value => {
      this.map.setFeatureState({
          source: 'admin-0',
          sourceLayer: BORDER_DATA,
          id: value
        },
        {
          hover: false,
          fillColor: undefined
        });
    });

    this.visitedCountries = new Array<CountryInfo>();
  }

  ngOnInit() {
    mapboxgl.accessToken = 'pk.eyJ1IjoiZGthbGFjaG5pdWsiLCJhIjoiZGQ5OGZkYjllY2RlNDA2ZWIwZDQ3MjBkNWNmNTIxZmYifQ.Abx602IWdGkI78RfNyKjoQ';
    this.map = new mapboxgl.Map({
      container: 'map',
      pitchWithRotate: false,
      dragRotate: false,
      center: [0, 0],
      maxBounds: [
        [-180, -80],
        [180, 85]
      ],
      style: 'mapbox://styles/dkalachniuk/ck3hdwz140gin1cpg0ik9k69a'
    });

    this.map.on('load', () => {
      this.map.getCanvas().style.cursor = 'default';
      this.map.addSource('admin-0', {
        type: 'vector',
        url: 'mapbox://dkalachniuk.8gbixsl1'
      });

      this.map.addLayer({
        id: 'my-country-fills',
        type: 'fill',
        source: 'admin-0',
        'source-layer': BORDER_DATA,
        layout: {},
        paint: {
          'fill-color': ['to-color', ['feature-state', 'fillColor'], '#455368'],
          'fill-opacity': ['case',
            ['boolean', ['feature-state', 'hover'], false],
            1,
            0
          ]
        }
      }, 'country-label');

      this.map.addLayer({
        id: 'my-country-borders',
        type: 'line',
        source: 'admin-0',
        'source-layer': BORDER_DATA,
        layout: {},
        paint: {
          'line-color': ['to-color', ['feature-state', 'fillColor'], '#3B4759'],
          'line-width': 1
        }
      }, 'country-label');

      this.features.next(new Map(this.map.querySourceFeatures('composite', {
        sourceLayer: BORDER_DATA
      }).map((e) => {
        return [e.properties.ISO3, e.id];
      })));

      this.dataService.mapFocus.subscribe(iso3 => {
        const countryList = this.dataService.countryList.getValue();
        if (countryList) {
          const bbox = countryList.find(c => c.iso3 === iso3).mapBorders;
          if (bbox) {
            this.map.fitBounds(bbox, {
              padding: {top: 20, bottom: 20, left: 20, right: 20}
            });
          }
        }
      });

      /*      this.dataService.mapData.next(new Map(this.map.querySourceFeatures('composite', {
              'sourceLayer': BORDER_DATA
            }).map((e) => {
              return [e.properties.ISO3, {lat: e.properties.LAT, lon: e.properties.LON}];
            })));*/


      /* let fData = this.map.querySourceFeatures('composite', {
         'sourceLayer': BORDER_DATA
       }).map((e) => {
         return {name: e.properties.NAME, iso3: e.properties.ISO3};
       });*/

      this.handleHover();

      this.onCountryClick();
    });

    // disable map rotation using right click + drag
    this.map.dragRotate.disable();

// disable map rotation using touch rotation gesture
    this.map.touchZoomRotate.disableRotation();

    this.map.setRenderWorldCopies(false);
    this.map.setMaxZoom(28);

    // Add map controls
    // this.map.addControl(new mapboxgl.NavigationControl());
    /* this.dataService.mapData.subscribe(value => {
       setTimeout(() => {
         /!*this.map.flyTo({
           center: [value.get('BEL').lon, value.get('BEL').lat],
           zoom: 8
         });*!/
         let bbox = [[1.9193492, 50.7295671], [7.2274985, 53.7253321]];
         this.map.fitBounds(bbox, {
           padding: {top: 20, bottom:20, left: 20, right: 20}
         })
       }, 2000);

     });*/

  }

  private onCountryClick() {
    this.map.on('click', 'my-country-fills', (event) => {
      const countryProps = event.features[0].properties;

      const cInfo = this.visitedCountries.find(vc => vc.iso3 === countryProps.ISO3);
      const dialogRef = this.dialog.open(CountryPickerModalComponent, {
        data:
          {
            name: countryProps.NAME,
            notes: cInfo ? cInfo.notes : '',
            actionType: this.visitedCountries.map(vc => vc.iso3).includes(countryProps.ISO3)
              ? CountryPickerActionType.UNSCRATCH
              : CountryPickerActionType.SCRATCH,
            iso3: countryProps.ISO3,
            polygonId: event.features[0].id
          }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result.actionType === CountryPickerActionType.SCRATCH) {
            this.user.visitedCountries.push({iso3: result.iso3, notes: result.notes, name: result.name});
            // TODO: find better solution to manage map events
            this.alreadyVisitedCountry = true;
            this.authService.updateLocalUserData(this.user);
          } else {
            this.dialog.open(ConfirmationModalComponent, {
              data:
                {
                  title: `Unscratch ${result.name}`,
                  description: `Are you sure you want to remove ${result.name}? <br/>All notes will be permanently deleted.`,
                  actionText: 'Unscratch',
                  actionIcon: 'delete'
                }
            }).afterClosed().subscribe((confirmationResult) => {
              if (confirmationResult) {
                this.authService.deleteUserCountry(result.iso3);
                this.actionService.eventDispatcher.next(EventType.COUNTRY_WAS_REMOVED);
              }
            });
          }
        }
      });

    });
  }

  private handleHover() {
    this.map.on('mousemove', 'my-country-fills', (e) => {
      this.map.getCanvas().style.cursor = 'pointer';
      const userCondition: boolean = this.user
        && this.visitedCountries.map(vc => vc.iso3).includes(e.features[0].properties.ISO3);

      if (this.hoveredStateId && !this.alreadyVisitedCountry) {
        this.map.setFeatureState({
          source: 'admin-0',
          sourceLayer: BORDER_DATA,
          id: this.hoveredStateId
        }, {hover: false});
      }

      if (e.features.length > 0 && !userCondition) {
        this.alreadyVisitedCountry = false;
        this.hoveredStateId = e.features[0].id;
        this.map.setFeatureState({
          source: 'admin-0',
          sourceLayer: BORDER_DATA,
          id: this.hoveredStateId
        }, {hover: true});
      } else {
        this.alreadyVisitedCountry = true;
      }
    });

    // When the mouse leaves the state-fill layer, update the feature state of the
// previously hovered feature.
    this.map.on('mouseleave', 'my-country-fills', (e) => {
      this.map.getCanvas().style.cursor = 'default';
      const userCondition: boolean = this.user && e.features
          && this.visitedCountries.map(vc => vc.iso3).includes(e.features[0].properties.ISO3);
      if (this.hoveredStateId && !userCondition) {
        this.map.setFeatureState({
          source: 'admin-0',
          sourceLayer: BORDER_DATA,
          id: this.hoveredStateId
        }, {hover: false});
      }
      this.hoveredStateId = null;
    });
  }

  private fillWithUserData() {
    const regionsMap = this.dataService.regions.getValue();
    if (Object.keys(regionsMap).length > 0 && this.user) {
      this.countries = Object.keys(regionsMap)
        .map(value => regionsMap[value])
        .reduce((previousValue, currentValue) => previousValue.concat(currentValue));

      this.countries.filter((c) => this.visitedCountries.map(vc => vc.iso3).includes(c.iso3)).forEach((c) => {
        const featureId = this.features.getValue().get(c.iso3);
        if (featureId) {
          this.map.setFeatureState({
              source: 'admin-0',
              sourceLayer: BORDER_DATA,
              id: featureId
            },
            {
              hover: true,
              fillColor: c.fillColor
            });
        }
      });
    }
  }

  ngAfterViewInit() {
    this.map.resize();
  }

  ngOnDestroy(): void {
  }
}
