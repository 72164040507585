import {Component, OnInit} from '@angular/core';
import {AuthProvider, AuthService} from '../services/auth.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ErrorHandlerService} from '../services/error-handler.service';
import {BreakpointObserver} from '@angular/cdk/layout';
import {LayoutConstant} from '../model/layout-constant';

@Component({
  selector: 'app-signup-user',
  templateUrl: './signup-user.component.html',
  styleUrls: ['./signup-user.component.scss']
})
export class SignupUserComponent implements OnInit {

  layout = LayoutConstant;
  handsetLandscape = false;
  hidePassword = true;

  registrationForm = new FormGroup({
    name: new FormControl(''),
    email: new FormControl('', [
      Validators.required,
      Validators.email,
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
      Validators.pattern('.*[0-9].*')
    ])
  });

  constructor(breakpointObserver: BreakpointObserver,
              public auth: AuthService,
              private router: Router,
              private errorHandler: ErrorHandlerService) {
    /* breakpointObserver.observe([
       Breakpoints.HandsetLandscape
     ]).subscribe(result => {
       this.handsetLandscape = result.matches;
     });*/
  }

  ngOnInit() {
  }

  async signUp(authProvider: AuthProvider) {
    const signingResult = await this.auth.providerSignin(authProvider);
    signingResult.mapErr(e => {
      this.errorHandler.showError(e.errorMessage.toString());
    });
    if (signingResult.isOk()) {
      await this.router.navigate(['profile'], {queryParamsHandling: 'merge'});
    }
  }

  async emailRegistration() {
    const emailSignup = await this.auth.emailSignup(this.registrationForm.value);
    emailSignup.mapErr(e => {
      this.errorHandler.showError(e.errorMessage.toString());
    });
    if (emailSignup.isOk()) {
      await this.router.navigate(['profile'], {queryParamsHandling: 'merge'});
    }
  }
}
