<div class="common-bottom-margin-lg relative-position">
  <div class="absolute-position">
    <button mat-icon-button routerLink="/menu">
      <mat-icon class="secondary-text-color">arrow_back</mat-icon>
    </button>
  </div>
  <div class="h4" fxLayoutAlign="center center">Profile settings</div>
</div>
<div *ngIf="auth.localUserData$ | async as user" fxLayout="column">
  <div fxLayoutAlign="center center" class="common-bottom-margin-lg">
    <img class="avatar" [src]="user.photoURL || '../../assets/avatar_guest.png'">
  </div>

  <form fxLayout="column" [formGroup]="userData" class="common-bottom-margin-lg">
    <mat-form-field fxFlex>
      <input matInput placeholder="Display name" [formControl]="userData.controls.name">
      <mat-icon class="secondary-text-color" matSuffix>mode_edit</mat-icon>
    </mat-form-field>
    <mat-form-field fxFlex>
      <input matInput disabled placeholder="Email" value="{{user.email}}">
    </mat-form-field>
  </form>

  <div [fxHide]="user.providerId && user.providerId !== 'password'" (click)="navigateTo('change-password')" fxLayout="row" class="common-bottom-margin-lg action-btn primary-color">
    <app-action-item-with-icon icon="lock"
                               iconClass="ps-action-icon"
                               innerText="Change password"
                               textClass="action-2 test-test">
    </app-action-item-with-icon>
  </div>

  <div (click)="onDeleteAccount()" fxLayout="row" class="common-bottom-margin-lg action-btn red-color">
    <app-action-item-with-icon icon="delete_forever"
                               iconClass="ps-action-icon"
                               innerText="Delete account"
                               textClass="action-2">
    </app-action-item-with-icon>

  </div>
</div>
