import {BREAKPOINT} from '@angular/flex-layout';

export const BR_QUERY = {
  XS : 'screen and (min-width: 320px) and (max-width: 479px)',
  SM: 'screen and (min-width: 479px) and (max-width: 959px)',
  LTLG: 'screen and (max-width: 959px)',
  LG: 'screen and (min-width: 960px)'
};

const ESCRATCH_BREAKPOINTS = [
  {
    alias: 'xs',
    suffix: 'Xs',
    mediaQuery: BR_QUERY.XS,
    overlapping: false,
    priority: 1001 // Needed if overriding the default print breakpoint
  },
  {
    alias: 'sm',
    suffix: 'Sm',
    mediaQuery: BR_QUERY.SM,
    overlapping: false,
    priority: 1001 // Needed if overriding the default print breakpoint
  },
  {
    alias: 'lt-lg',
    suffix: 'LtLg',
    mediaQuery: BR_QUERY.LTLG,
    overlapping: false,
    priority: 1001 // Needed if overriding the default print breakpoint
  },
  {
    alias: 'lg',
    suffix: 'Lg',
    mediaQuery: BR_QUERY.LG,
    overlapping: false,
    priority: 1001 // Needed if overriding the default print breakpoint
  }
];

export const CustomBreakPointsProvider = {
  provide: BREAKPOINT,
  useValue: ESCRATCH_BREAKPOINTS,
  multi: true
};
