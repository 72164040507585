import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {Router} from '@angular/router';
import {BreakpointObserver} from '@angular/cdk/layout';
import {ActionHandlerService, AppAction} from '../services/action-handler.service';
import {DataService} from '../services/data.service';
import {Subscription} from 'rxjs';
import {Country} from '../model/country';
import {User} from '../model/user.model';
import {LayoutConstant} from '../model/layout-constant';

const countryComparator = (a, b) => {
  const nameA = a.name.toUpperCase(); // ignore upper and lowercase
  const nameB = b.name.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  // names must be equal
  return 0;
};

@Component({
  selector: 'app-all-countries',
  templateUrl: './all-countries.component.html',
  styleUrls: ['./all-countries.component.scss']
})
export class AllCountriesComponent implements OnInit, OnDestroy {
  layout = LayoutConstant;
  allFlagsCount = 0;
  private regionsSubscription: Subscription;
  groupedCountries: Array<Array<Country>> = new Array<Array<Country>>();

  constructor(public auth: AuthService,
              private router: Router,
              public dataService: DataService,
              breakpointObserver: BreakpointObserver,
              private actionHandler: ActionHandlerService) {

    this.regionsSubscription = this.dataService.regions.subscribe(regions => {
      if (regions) {
        const countriesData: Array<Country> = Object.keys(regions)
          .map(region => regions[region])
          .reduce((previousValue, currentValue) => previousValue.concat(currentValue), []);

        countriesData.sort(countryComparator);

        countriesData.forEach((value, index) => {
          if (index % 3 === 0) {
            this.groupedCountries.push([value]);
          } else {
            this.groupedCountries[this.groupedCountries.length - 1].push(value);
          }
        });

        // console.log("regions");
        // console.log(this.countries.map(value => {return {name: value.name, iso3: value.iso3}}));
        this.allFlagsCount = Object.keys(regions)
          .map(region => regions[region].length)
          .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
      }
    });

    let nextAction = this.actionHandler.getNextAction();
    if (!nextAction) {
      nextAction = this.actionHandler.defaultCloseAction;
    }
    this.actionHandler.floatButtonAction.next(nextAction);
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.regionsSubscription.unsubscribe();
  }

  isNotVisited(user: User, iso3: string) {
    return user.visitedCountries.find(value => value.iso3 === iso3) === undefined;
  }
}
